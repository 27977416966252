<template>
  <b-modal
    ref="myModal"
    v-if="getModalData.modalState && getModalData.modalState != ''"
    v-model="getModalData.modalIsActive"
    :hide-footer="true"
    centered
    :size="getModalData.modalSize"
    :id="getModalData.modalState"
    @shown="handleModalShown"
    lazy
  >
    <template v-slot:header v-if="getModalData.modalState !== 'departmentSearchModal' && getModalData.modalState !== 'creditMemoEdit'">
      <h2 v-if="getModalData.modalState === 'splitOrderModal' || getModalData.modalState === 'splitTradeModal'">Split Order</h2>
      <h2 v-else-if="getModalData.modalState === 'login'">Session Expired - Please Sign In Again</h2>
      <h2 v-else-if="getModalData.modalState === 'passwordReset'">Change Password</h2>
      <h2 v-else-if="getModalData.modalState === 'requestReset'">Password Reset</h2>
      <h2 v-else-if="getModalData.modalState === 'noteOnboarding'">{{ getCurrentNoteOrder.CorporateName || getCurrentNoteOrder.CorporateNameTrade }}</h2>
      <h2 v-else-if="getModalData.modalState === 'notesGeneral'">{{ getCurrentNoteOrder.CorporateName || getCurrentNoteOrder.TradeCorporateName || getCurrentNoteOrder.CorporateNameTrade }}
        {{ getModalData.modalCurrentVin && $route.name === 'accounts-receivable' ? `- ${getModalData.modalCurrentVin.UnitNumber}` : null }}</h2>
      <h2 v-else-if="getModalData.modalState === 'editOffboarding' || getModalData.modalState === 'editOnboarding' || 
        getModalData.modalState === 'editOnTrade' || getModalData.modalState === 'editOffTrade'">Edit Order</h2>
      <h2 v-else-if="getModalData.modalState === 'creditMemoCreate'">Create Credit Memo</h2>
      <h2 v-else-if="getModalData.modalState === 'creditMemoApply'">Apply Credit Memo</h2>
      <h2 v-else-if="getModalData.modalState === 'setPriorityOrder'">{{ getCurrentNoteOrder.CorporateName || getCurrentNoteOrder.CorporateNameTrade }}</h2>
      <h2 v-else-if="getModalData.modalState === 'removePrepayOrder'">{{ getCurrentNoteOrder.CorporateName }}</h2>
      <h2 v-else-if="getModalData.modalState === 'vendorModal'">Create Vendor</h2>
      <button type="button" class="btn-close" @click="$refs.myModal.hide()" aria-label="Close"></button>
    </template>
    <hr v-if="getModalData.modalState !== 'statusDetailsModal' && getModalData.modalState !== 'departmentSearchModal' &&
      getModalData.modalState !== 'departmentCreate' && getModalData.modalState !== 'creditMemoEdit'" class="modal-hr">
    <!--// - Modal States -->
    <PasswordReset v-if="getModalData.modalState === 'passwordReset'" @closeModal="closeTheModal" />
    <Login v-if="getModalData.modalState === 'login'" @closeModal="closeTheModal" />
    <NoteOnboarding v-if="getModalData.modalState === 'noteOnboarding'" @closeModal="closeTheModal" />
    <NotesGeneral v-if="getModalData.modalState === 'notesGeneral'" ref="childComponent" @closeModal="closeTheModal" />
    <RequestReset v-if="getModalData.modalState === 'requestReset'" @closeModal="closeTheModal" />
    <EditOffboarding v-if="getModalData.modalState === 'editOffboarding'" @closeModal="closeTheModal" />
    <EditOnboarding v-if="getModalData.modalState === 'editOnboarding'" @closeModal="closeTheModal" />
    <EditOnTrade v-if="getModalData.modalState === 'editOnTrade'" @closeModal="closeTheModal" />
    <EditOffTrade v-if="getModalData.modalState === 'editOffTrade'" @closeModal="closeTheModal" />
    <CreditMemoCreate v-if="getModalData.modalState === 'creditMemoCreate'" @closeModal="closeTheModal" />
    <CreditMemoApply v-if="getModalData.modalState === 'creditMemoApply'" @closeModal="closeTheModal" />
    <CreditMemoEdit v-if="getModalData.modalState === 'creditMemoEdit'" @closeModal="closeTheModal" />
    <SetPriorityOrder v-if="getModalData.modalState === 'setPriorityOrder'" @closeModal="closeTheModal" />
    <RemovePrepayOrder v-if="getModalData.modalState === 'removePrepayOrder'" @closeModal="closeTheModal" />
    <StatusDetailsModal v-if="getModalData.modalState === 'statusDetailsModal'" @closeModal="closeTheModal" />
    <DepartmentSearchModal v-if="getModalData.modalState === 'departmentSearchModal'" @closeModal="closeTheModal" />
    <!-- <HistorySearchModal v-if="getModalData.modalState === 'historySearchModal'"/> -->
    <VendorModal v-if="getModalData.modalState === 'vendorModal'" :vendors="getModalData.modalItem" @closeModal="closeTheModal" />
    <DepartmentCreate v-if="getModalData.modalState === 'departmentCreate'" :departments="getModalData.modalItem" @closeModal="closeTheModal" />
    <ManheimDetails v-if="getModalData.modalState === 'manheimDetails'" @closeModal="closeTheModal" />
    <SplitOrderModal v-if="getModalData.modalState === 'splitOrderModal'" :order="getModalData.modalItem" @closeModal="closeTheModal" />
    <SplitTradeModal v-if="getModalData.modalState === 'splitTradeModal'" :order="getModalData.modalItem" @closeModal="closeTheModal" />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PasswordReset from './modalStates/auth/PasswordReset';
import Login from './modalStates/auth/Login';
import NoteOnboarding from './modalStates/NoteOnboarding';
import NotesGeneral from './modalStates/NotesGeneral';
import EditOffboarding from './modalStates/editModals/EditOffboarding';
import EditOnboarding from './modalStates/editModals/EditOnboarding';
import EditOnTrade from './modalStates/editModals/EditOnTrade';
import EditOffTrade from './modalStates/editModals/EditOffTrade';
import RequestReset from './modalStates/auth/RequestReset';
import CreditMemoCreate from './modalStates/CreditMemoCreate';
import CreditMemoApply from './modalStates/CreditMemoApply';
import CreditMemoEdit from './modalStates/CreditMemoEdit';
import SetPriorityOrder from './modalStates/SetPriorityOrder';
import RemovePrepayOrder from './modalStates/RemovePrepayOrder';
import StatusDetailsModal from '@/components/modalStates/StatusDetailsModal';
import DepartmentSearchModal from '@/components/modalStates/DepartmentSearchModal';
// import HistorySearchModal from '@/components/modalStates/HistorySearchModal';
import VendorModal from '@/components/modalStates/VendorModal';
import DepartmentCreate from '@/components/modalStates/DepartmentCreate';
import ManheimDetails from './modalStates/ManheimDetails.vue';
import SplitOrderModal from './modalStates/SplitOrderModal.vue';
import SplitTradeModal from './modalStates/SplitTradeModal.vue';

export default {
  name: 'modal',
  components: {
    PasswordReset,
    Login,
    NoteOnboarding,
    NotesGeneral,
    EditOffboarding,
    EditOnboarding,
    EditOnTrade,
    EditOffTrade,
    RequestReset,
    CreditMemoCreate,
    CreditMemoApply,
    CreditMemoEdit,
    SetPriorityOrder,
    RemovePrepayOrder,
    StatusDetailsModal,
    DepartmentSearchModal,
    // HistorySearchModal,
    VendorModal,
    DepartmentCreate,
    ManheimDetails,
    SplitOrderModal,
    SplitTradeModal,
  },
  created() {
    // reset the modalState to prevent recursive calls
    this.getModalData.modalState = '';
  },
  mounted() {
    this.$store.watch(
      (state, getters) => getters.getModalData,
      () => {
        console.log('modal watch running');
        this.masterOrders = this.getModalData;
        this.combinedMasterOrders = [...this.masterOrders];
        this.filteredOrders = this.combinedMasterOrders;
      }
    );
  },
  beforeDestroy() {
    // Clean up the watch 
    this.getModalData.modalState = '';
  },
  computed: mapGetters(['getModalData', 'getModalItem', 'getCurrentNoteOrder']),
  methods: {
    ...mapActions(['closeModal']),
    handleModalShown() {
      if (this.getModalData.modalState == 'notesGeneral') {
        const child = this.$refs.childComponent;
        if (child) {
          child.onModalShown()
        }
      }
    },
    closeTheModal() {
      this.getModalData.modalIsActive = false;
    }
    // validation: function(args) {
    //   console.log('before close validation');
    //   if (this.getModalData.modalState == 'historySearchModal') {
    //     console.log('cancel is true');
    //     args.cancel = true;
    //   } else {
    //     console.log('cancel is false');
    //     args.cancel = false;
    //   }
    // }
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/index';

// #historySearchModal > .modal-xl {
//   min-width: 80vw;
// }
</style>
