import axios from 'axios';
import constants from '../../assets/constants';
import { io } from "socket.io-client";

export default {
  // namespaced: true,
  state: {
    truckOffRentOrders: {
      offRentOrders: [],
      offRentOrdersAR: [],
      offRentOrdersTransport: [],
      offRentOrdersRecon: [],
      shippingOrdersTransport: [],
    },
    truckOrders: {
      Orders: [],
      currentOrderNotes: [],
      allAssignedOrders: [],
      allOpenContractsAR: [],
      currentNoteOrder: {},
    },
    truckClaims: {
      allOpenClaimsAR: [],
    },
    truckOrdersCR: {
      allRecentDeliveries: [],
    },
    truckAuctionSales: {
      allAtAuction: [],
    },
    truckTitles: {
      titles: [],
    },
    overviewTrucks: {
      trucks: [],
    },
    creditMemos: {
      allCreditMemos: [],
    }
  },
  getters: {
    // -  On Rents
    getAllOrders: (state) => state.truckOrders.Orders,
    getAllAssignedOrders: (state) => state.truckOrders.allAssignedOrders,
    getCurrentOrderNotes: (state) => state.truckOrders.currentOrderNotes,
    getCurrentNoteOrder: (state) => state.truckOrders.currentNoteOrder,
    // - Off Rents
    getAllOffRentOrders: (state) => state.truckOffRentOrders.offRentOrders,
    getOrdersForTransport: (state) => state.truckOffRentOrders.offRentOrdersTransport,
    getShippingForTransport: (state) => state.truckOffRentOrders.shippingOrdersTransport,
    // - Accounts Receivable
    getAllOpenContractsAR: (state) => state.truckOrders.allOpenContractsAR,
    getOffRentContractsAR: (state) => state.truckOffRentOrders.offRentOrdersAR,
    getAllOpenClaimsAR: (state) => state.truckClaims.allOpenClaimsAR,
    //  - Recon
    getAllReconOrders: (state) => state.truckOffRentOrders.offRentOrdersRecon,
    // - Client Relations
    getAllRecentDeliveries: (state) => state.truckOrdersCR.allRecentDeliveries,
    // - Sales
    getSalesAtAuction: (state) => state.truckAuctionSales.allAtAuction,
    // - Titles
    getTitles: (state) => state.truckTitles.titles,
    // - Overview Trucks
    getOverviewTrucks: (state) => state.overviewTrucks.trucks,
    // - Credit Memos
    getAllCreditMemos: (state) => state.creditMemos.allCreditMemos,
  },
  mutations: {
    setSocket(state, socket) {
      state.socket = socket;
    },
    setConnected(state, connected) {
      state.connected = connected;
    },
    setMessage(state, message) {
      state.message = message;
    },
    // -  On Rents
    setCurrentOrderNotes(state, payload) {
      state.truckOrders.currentOrderNotes = payload;
    },
    setCurrentNoteOrder(state, payload) {
      state.truckOrders.currentNoteOrder = payload;
    },
    //  - Sockets
    // * OnBoarding
    SOCKET_onBoardingCardsEmit(state, payload) {
      state.truckOrders.Orders = payload;
    },
    // * OffBoarding
    SOCKET_offBoardingCardsEmit(state, payload) {
      state.truckOffRentOrders.offRentOrders = payload;
    },
    // * Transport
    SOCKET_transportCardsRefreshEmit(state, payload) {
      state.truckOffRentOrders.offRentOrdersTransport = payload;
    },
    SOCKET_shippingCardsRefreshEmit(state, payload) {
      state.truckOffRentOrders.shippingOrdersTransport = payload;
    },
    // * Accounts Receivable
    SOCKET_arOffRentCardsRefreshEmit(state, payload) {
      state.truckOffRentOrders.offRentOrdersAR = payload;
    },
    SOCKET_arOpenCardsRefreshEmit(state, payload) {
      state.truckOrders.allOpenContractsAR = payload;
    },
    SOCKET_arClaimsCardsRefreshEmit(state, payload) {
      state.truckClaims.allOpenClaimsAR = payload;
    },
    // * Client Relations
    SOCKET_crCardsRefreshEmit(state, payload) {
      state.truckOrdersCR.allRecentDeliveries = payload;
    },
    // * Recon
    SOCKET_reconCardsRefreshEmit(state, payload) {
      state.truckOffRentOrders.offRentOrdersRecon = payload;
    },
    // * Sales
    SOCKET_salesCardsRefreshEmit(state, payload) {
      state.truckAuctionSales.allAtAuction = payload;
    },
    // * Titles
    SOCKET_titlesCardsRefreshEmit(state, payload) {
      state.truckTitles.titles = payload;
    },
    // * Overview
    SOCKET_overviewCardsRefreshEmit(state, payload) {
      state.overviewTrucks.trucks = payload;
    },
    // * Credit Memos
    SOCKET_creditMemosRefreshEmit(state, payload) {
      state.creditMemos.allCreditMemos = payload;
    }
  },
  actions: {
    connect({ commit }) {
      const socket = io(constants.BASE_URL, {});
      socket.on("connect", () => {
        commit("setConnected", true);
      });
  
      socket.on("disconnect", () => {
        commit("setConnected", false);
      });
      commit("setSocket", socket);
    },
    disconnect({ state }) {
      if (state.socket) {
        state.socket.disconnect();
      }
    },
    // -  On Rents
    fetchNotes({ commit }, formHistoryID) {
      let formHistID;
      let unitNum;
      if (formHistoryID.form) {
        formHistID = formHistoryID.form;
        unitNum = formHistoryID.unit;
      } else {
        formHistID = formHistoryID;
        unitNum = 'IgnoreUnit';
      }
      axios
        .get(`${constants.BASE_URL}/api/orders/${formHistID}/notes/${unitNum}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((res) => {
          commit('setCurrentOrderNotes', res.data);
        })
        .catch((error) => {
          console.log('error getting notes: ', error);
        });
    },
    fetchCurrentNoteOrder({ commit }, payload) {
      /*
      One of the locations for the root of this data:
         "CALL getApprovedTransport();",
      */

      commit('setCurrentNoteOrder', payload);
    },
    fetchAllUnitNotes({ commit }, unitNum) {
      axios
        .get(`${constants.BASE_URL}/api/all-unit-notes/${unitNum}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((res) => {
          commit('setCurrentOrderNotes', res.data);
        })
        .catch((error) => {
          console.log('error getting notes: ', error);
        });
    }
  },
};
