<template>
  <div v-if="shouldDisplay" id="feedback">
    <div
      @click="feedbackActive = !feedbackActive"
      :class="{ 'drop-prompt': feedbackActive }"
      id="feedback__header"
    >
      <p>Feedback</p>
    </div>
    <div :class="{ 'expand-content': feedbackActive }" id="feedback-container">
      <div @click="feedbackActive = !feedbackActive" id="feedback-header">
        <p>
          Dashboard Feedback
        </p>
        <font-awesome-icon class="feedback-icon" icon="caret-down" />
      </div>
      <div id="form-container">
        <form @submit.prevent="">
          <p id="error">{{ ui.error }}</p>

          <label class="input-label">Name :</label>
          <BFormInput
            v-model="formData.name"
            size="sm"
            placeholder="Enter your name..."
          ></BFormInput>
          <label class="input-label">Feedback :</label>
          <BFormTextarea
            v-model="formData.message"
            size="sm"
            required
            placeholder="Enter your feedback..."
          ></BFormTextarea>
        </form>
        <BButton
          @click="handleFeedbackSubmission"
          variant="primary"
          class="px-3 mt-3"
          size="sm"
          v-if="!ui.loading"
          >Send</BButton
        >
        <BSpinner
          v-else
          variant="primary"
          type="grow"
          label="Spinning"
          id="feedback-loader"
        ></BSpinner>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'feedback',
  data() {
    return {
      feedbackActive: false,
      formData: {
        name: '',
        message: '',
      },
      ui: {
        error: '',
        loading: false,
      },
      shouldDisplay: true,
    };
  },
  watch: {
    $route(to, from) {
      this.modalShouldDisplay();
    },
  },
  mounted() {
    this.modalShouldDisplay();
  },
  methods: {
    modalShouldDisplay() {
      const dismissFeedbackRoutes = [
        'orderForm',
        'offRentForm',
        'login',
        'DamageForm',
        'Register',
        'passwordResetExternal',
        'documentation',
        'tradeForm',
      ];

      if (
        !localStorage.getItem('token')
        || dismissFeedbackRoutes.includes(this.$route.name)
      ) {
        this.shouldDisplay = false;
        return;
      }

      return (this.shouldDisplay = true);
    },
    handleFeedbackSubmission() {
      this.ui.loading = true;
      if (
        this.formData.name.trim() === ''
        || this.formData.message.trim() === ''
      ) {
        setTimeout(() => (this.ui.error = ''), 5000);
        this.ui.loading = false;
        return (this.ui.error = 'All fields are required');
      }

      axios
        .post(`${constants.BASE_URL}/feedback`, {
          ...this.formData,
          url: window.location.href,
        })
        .then((res) => {
          this.formData.name = '';
          this.formData.message = '';
          this.feedbackActive = false;
          this.ui.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index";

#error {
  position: absolute;
  bottom: 20px;
  right: 30%;
  color: red;
  font-size: 0.8rem;
}

#feedback-loader {
  margin: 10px 0 0;
}

#feedback {
  position: fixed;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: -20rem;
  right: 20px;
  border-radius: 1rem;
  max-width: 100px;
}

#feedback__header {
  width: 5rem;
  background: #3c78d3;
  border-radius: 1rem;
  overflow: hidden;
  @include F();
  padding: 5px 0px;
  margin: 0 0 2rem;
  cursor: pointer;
  transition: 400ms ease-in-out all;
  p {
    color: #fff;
    font-weight: 700;
  }
}

#feedback-container {
  position: relative;
  z-index: 83475209;
  background: #3c78d3;
  border-radius: 0.2rem 0.2rem 0 0;
  width: 23rem;
  transition: 400ms ease-in-out all;
}

#feedback-header {
  @include F($j: space-between);
  height: 2.5rem;
  padding: 0 20px;
  color: #fff;
  cursor: pointer;
  p {
    font-weight: 700;
  }
  &:hover {
    .feedback-icon {
      transform: translateY(15%);
    }
  }
}

.feedback-icon {
  transition: 100ms ease-in-out all;
}

#form-container {
  width: 100%;
  background: #333;
  padding: 10px 20px;
  position: relative;
  z-index: 5;

  form {

    width: 90%;
    input {
      margin: 0 0 5px;
      background: none;
      color: #c7cacd;
      border-color: #ffffff48;
      &::placeholder {
        color: #c7cacd70;
      }
    }
    textarea {
      background: none;
      color: #c7cacd;
      border-color: #ffffff48;
      height: 7rem;
      &::placeholder {
        color: #c7cacd70;
      }
    }
  }
}

.input-label {
  color: #eee;
  margin: 0;
  font-size: 0.8rem;
}

// - transition css
.expand-content {
  transform: translateY(-105%);
    position: absolute;
  z-index: 5;
}

.drop-prompt {
  transform: translateX(200%);
}

@media print {
  #feedback {
    display: none;
  }
}
</style>
