export default {
  state: {
    notifications: [],
    modalData: {
      modalState: '',
      modalSize: '',
      modalItem: [],
      modalIsActive: false,
      modalCurrentVin: {},
      modalCurrentStatus: '',
      modalSearchValue: '',
      modalCurrentNewItem: '',
      modalCurrentNewAvailability: '',
      modalCurrentNewModel: '',
    },
  },
  getters: {
    getModalData: (state) => state.modalData,
    // * Notifications
    getNotifications: (state) => state.notifications,
  },
  mutations: {
    setCloseModal(state) {
      state.modalData.modalState = '';
      state.modalData.modalIsActive = false;
    },
    setCurrentVin(state, payload) {
      state.modalData.modalCurrentVin = payload;
    },
    setCurrentStatus(state, payload) {
      state.modalData.modalCurrentStatus = payload;
    },
    setCurrentSearch(state, payload) {
      state.modalData.modalSearchValue = payload;
    },
    setCurrentNewItem(state, payload) {
      state.modalData.modalCurrentNewItem = payload;
    },
    setCurrentNewAvailability(state, payload) {
      state.modalData.modalCurrentNewAvailability = payload;
    },
    setCurrentNewModel(state, payload) {
      state.modalData.modalCurrentNewModel = payload;
    },
    setOpenModal(state, payload) {
      // - trinary accounts for and edge case with an expired token - called in router/index.js and axios interceptor
      state.modalData.modalState = payload.modalState
        ? payload.modalState
        : 'login';
      state.modalData.modalSize = payload.modalSize;
      state.modalData.modalItem = payload.modalItem;
      state.modalData.modalIsActive = true;
    },
    // * Notifications
    SOCKET_notificationsRefreshEmit(state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    closeModal({ commit }) {
      commit('setCloseModal');
    },
    openModal({ commit }, modalState, modalSize, modalItem) {
      const modalOptions = ['sm', 'lg', 'xl'];
      let test1 = [];
      let sizeOfModal = '';
      if (modalState && modalState.modalSize) {
        sizeOfModal = modalState.modalSize;
      } else if (modalSize) {
        sizeOfModal = modalSize;
      } else {
        sizeOfModal = 'lg';
      }
      if (modalState && modalState.modalItem) {
        test1 = modalState.modalItem;
      }
      modalState = modalState && modalState.modalState ? modalState.modalState : modalState;
      modalSize = sizeOfModal;
      // sizeOfModal = modalState && modalState.modalSize ? modalState.modalSize : modalSize;
      modalItem = test1;
      // if (modalOptions.includes(sizeOfModal)) {
      //   modalSize = sizeOfModal;
      // } else {
      //   modalSize = 'lg';
      // }
      if (sizeOfModal && !modalOptions.includes(sizeOfModal)) {
        throw new Error('Invalid modal size passed as prop');
      }
      const payload = {
        modalState,
        modalSize,
        modalItem,
      };
      commit('setOpenModal', payload);
    },
    fetchModalCurrentVin({ commit }, vin) {
      commit('setCurrentVin', vin);
    },
    fetchModalCurrentStatus({ commit }, status) {
      commit('setCurrentStatus', status)
    },
    fetchModalSearchValue({ commit }, status) {
      commit('setCurrentSearch', status)
    },
    fetchModalCurrentNewItem({ commit }, item) {
      commit('setCurrentNewItem', item)
    },
    fetchModalCurrentNewAvailability({ commit }, availability) {
      commit('setCurrentNewAvailability', availability)
    },
    fetchModalCurrentNewModel({ commit }, truckModel) {
      commit('setCurrentNewModel', truckModel)
    }
  },
};
