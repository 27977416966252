<template>
  <div id="modal__departmentDetails">
    <BRow align-h="between">
      <!-- <BCol xs="12" sm="4">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      <BCol> -->
      <BCol sm="12" align="center">
        <h3 class="department-search">{{ currentSearch }}</h3>
      
        <hr class="modal-hr">
      </BCol>
      <!-- <BCol sm="2">
        <button @click.stop="() => exportDataToCSV('status-details-table', 'StatusDetails')" class="export-excel__btn">
          Download Excel <font-awesome-icon icon="file-csv" />
        </button>
      <BCol> -->
      <!-- <BCol xs="6" sm="3" md="1" class="perPageDropdown">
        <b-dropdown
          id="per-page-dropdown"
          text="Per Page"
          variant="outline-primary"
        >
          <b-dropdown-item id="perPage25" active @click="showPerPage(25)">25</b-dropdown-item>
          <b-dropdown-item id="perPage50" @click="showPerPage(50)">50</b-dropdown-item>
          <b-dropdown-item id="perPage100" @click="showPerPage(100)">100</b-dropdown-item>
          <b-dropdown-item id="perPageAll" @click="showPerPage('All')">All</b-dropdown-item>
        </b-dropdown>
      <BCol> -->
    </BRow>
    <div v-if="searchDetails.length > 0">
      <h4>
        Current Status: {{ searchDetails[0].CurrentStatus }}
      </h4>
      <h4>
        Department: {{ searchDetails[0].Dept }}
      </h4>
      <h4>
        Full VIN: {{ searchDetails[0].VinNumber }}
      </h4>
      <div class="router-link">
        <router-link
          v-if="searchDetails[0].Dept == 'Transport' || searchDetails[0].Dept == 'Transport & AR'"
          class="search-link"
          v-on:click="$emit('closeModal')"
          :to="'/transport?filter='+searchDetails[0].UnitNumber"
          >Transport</router-link
        >
        <router-link
          v-if="searchDetails[0].Dept == 'AR' || searchDetails[0].Dept == 'Transport & AR'"
          class="search-link"
          v-on:click="$emit('closeModal')"
          :to="'/accounts-receivable?filter='+searchDetails[0].UnitNumber"
          >AR</router-link
        >
        <router-link
          v-if="searchDetails[0].Dept == 'Recon'"
          class="search-link" 
          v-on:click="$emit('closeModal')"
          :to="'/recon?filter='+searchDetails[0].UnitNumber"
          >Recon</router-link
        >
        <router-link
          v-if="searchDetails[0].Dept == 'Sales'" 
          class="search-link" 
          v-on:click="$emit('closeModal')"
          :to="'/sales?filter='+searchDetails[0].UnitNumber"
          >Sales</router-link
        >
        <router-link
          v-if="searchDetails[0].Dept == 'Titles'" 
          class="search-link" 
          v-on:click="$emit('closeModal')"
          :to="'/titles?filter='+searchDetails[0].UnitNumber"
          >Titles</router-link
        >
      </div>
      <div align="middle" class="unit-hub-div">
        <router-link
          class="unit-hub-link" 
          v-on:click="$emit('closeModal')"
          :to="'/unit-hub?search='+searchDetails[0].UnitNumber"
          >View on Unit Hub</router-link
        >
      </div>
      <div align="right" class="status-filter">
        <b-dropdown
          size="sm"
          right
          :text="filter.statusSelector"
          variant="outline-primary"
          class="m-2"
        >
          <b-dropdown-item v-if="filter.statusSelector !== 'All'" @click="filterStatus('All')"
            >All</b-dropdown-item
          >
          <b-dropdown-item v-if="filter.statusSelector !== 'Status'" @click="filterStatus('Status')"
            >Status</b-dropdown-item
          >
          <b-dropdown-item v-if="filter.statusSelector !== 'Sub Status'" @click="filterStatus('Sub Status')"
            >Sub Status</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <div v-else>
      <h5 v-if="searchDetails.length == 0">No results found. Please search a valid Unit Number.</h5>
    </div>
    <div v-if="unitHistory.length > 0">
      <hr>
      <b-table
        small
        bordered
        :fields="historyFields"
        :items="unitHistory"
        class="card-status-table">
        <div slot="table-busy" class="text-center text-danger my-2">
          <BSpinner class="align-middle"></BSpinner>
          <strong> Loading...</strong>
        </div>
        <template v-slot:cell(ChangedOn)="data">
          <span>{{ $moment(data.item.ChangedOn, 'MM/DD/YYYY, h:mm A') }}</span>
        </template>
      </b-table>
    </div>
    <div v-if="stillLoadingSearchDetails == true">
      <div slot="table-busy" class="text-center text-danger my-2">
        <BSpinner class="align-middle"></BSpinner>
        <strong> Loading...</strong>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'department-search',
  data() {
    return {
      // StatusDetailFields: [
      //   { key: 'OverrideLate', label: 'Late', sortable: false},
      //   { key: 'UnitNumber', label: 'Unit', sortable: true },
      //   { key: 'TruckStatusDescription', label: "Status", sortable: false },
      //   { key: 'SubStatus', sortable: true },
      //   { key: 'TruckNotes', label: 'Notes', sortable: true },
      //   { key: 'ChangeDate', label: 'Changed', sortable: true }
      // ],
      // statusTable: [],
      searchDetails: [],
      unitHistory: [],
      unitHistoryMaster: [],
      // statusDetailsOpen: false,
      stillLoadingSearchDetails: true,
      stillLoadingSearchHistory: true,
      currentSearch: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      historyFields: [
        { key: 'UnitNumber', label: 'Unit', sortable: false },
        { key: 'StatType', label: 'Status', sortable: true },
        { key: 'PrevVal', sortable: true },
        { key: 'NewVal', sortable: true },
        { key: 'ChangedOn', sortable: true },
        { key: 'ChangedBy', sortable: true },
        { key: 'ChangedFrom', sortable: true },
      ],
      filter: {
        statusSelector: 'All',
      },
    };
  },
  computed: mapGetters(['getModalData']),
  mounted() {
    this.searchValue(this.getModalData.modalSearchValue);
    this.searchHistory(this.getModalData.modalSearchValue);
  },
  methods: {
    ...mapActions(['closeModal']),
    searchValue(e) {
      this.currentSearch = e;
      this.stillLoadingSearchDetails = true;
      this.perPage = 25;
      axios
        .get(`${constants.BASE_URL}/api/departmentSearch/${e}`, {
          headers: { Authorization: localStorage.getItem('token') },
        })
        .then(result => {
          this.searchDetails = result.data;
          this.totalRows = result.data.length;
          this.stillLoadingSearchDetails = false;
        }).catch(err => {
          console.log('error: ', err);
        })
    },
    searchHistory(e) {
      this.stillLoadingSearchHistory = true;
      axios
        .get(`${constants.BASE_URL}/api/unitHistorySearch/${e}`, {
          headers: { Authorization: localStorage.getItem('token') },
        })
        .then(result => {
          this.unitHistory = result.data;
          this.unitHistoryMaster = result.data;
          this.stillLoadingSearchHistory = false;
        }).catch(err => {
          console.log('error: ', err);
          this.stillLoadingSearchHistory = false;
        })
    },
    showPerPage(amount) {
      const dropdownContainer = document.getElementById('per-page-dropdown');
      const dropdowns = dropdownContainer.getElementsByClassName('dropdown-item');
      for (let i = 0; i < dropdowns.length; i++) {
        if (dropdowns[i].classList.contains('active')) {
          dropdowns[i].classList.toggle('active');
        }
      }
      if (amount === 'All') {
        this.perPage = this.statusDetails.length;
        document.getElementById('perPageAll').classList.add('active');
      } else if (amount === 100) {
        this.perPage = amount;
        document.getElementById('perPage100').classList.add('active');
      } else if (amount === 50) {
        this.perPage = amount;
        document.getElementById('perPage50').classList.add('active');
      } else if (amount === 25) {
        this.perPage = amount;
        document.getElementById('perPage25').classList.add('active');
      }
    },
    exportDataToCSV() {
      let excelDownloadItems = [];
      for (let i = 0; i < this.statusDetails.length; i++) {
        excelDownloadItems.push({
          UnitNumber: this.statusDetails[i].UnitNumber,
          Status: this.statusDetails[i].TruckStatusDescription,
          SubStatus: this.statusDetails[i].SubStatus,
          TruckNotes: this.statusDetails[i].TruckNotes,
          LastChangedOn: this.statusDetails[i].ChangeDate,
          Late: this.statusDetails[i].OverrideLate != 1 && 
            (((this.currentStatus == 'Sold Not Delivered' || this.currentStatus == 'IN TRANSIT' || 
            this.currentStatus == 'IN TRANSIT (SELLER)' || this.currentStatus == 'ON LOT')
            && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (7*24*60*60*1000)) 
            ||
            ((this.currentStatus == 'NEW' || this.currentStatus == 'OFF RENT' || 
              this.currentStatus == 'RE-RENT' || this.currentStatus == 'BODY SHOP')
            && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (14*24*60*60*1000)) 
            ||
            ((this.currentStatus == 'Hold for Sale')
            && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (21*24*60*60*1000)) 
            ||
            ((this.currentStatus == 'At Auction' || this.currentStatus == 'In Maintenance' || 
              this.currentStatus == 'FOR SALE' || this.currentStatus == 'RETAIL')
            && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (28*24*60*60*1000)) 
            ||
            ((this.currentStatus == 'Claims')
            && new Date(this.statusDetails[i].ChangeDate) < new Date().getTime() - (56*24*60*60*1000))) ? 
              'Yes' : this.statusDetails[i].OverrideReason != null ? 'No, ' + this.statusDetails[i].OverrideReason : 'No'
        })
      }
      let d = new Date();
      let fileTime = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
      let fileName = `${this.currentStatus + 'Status' + fileTime}.csv`;
      const items = excelDownloadItems;
      const replacer = (key, value) => value === null ? '' : value // specify how to handle null values here
      const header = Object.keys(items[0]);
      let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '"').replace('\n', '\\n')).join(','))
      csv.unshift(header.join(','))
      this.downloadCSV(csv.join('\r\n'), fileName);
    },
    downloadCSV(csv, filename) {
      const csvFile = new Blob([csv], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    filterStatus(filter) {
      this.unitHistory = [];
      this.filter.statusSelector = filter;
      if (filter == 'All') {
        this.unitHistory = this.unitHistoryMaster;
        return;
      }
      for (let i = 0; i < this.unitHistoryMaster.length; i++) {
        if (filter == 'Status' && this.unitHistoryMaster[i].StatType == 'Status') {
          this.unitHistory.push(this.unitHistoryMaster[i]);
        } else if (filter == 'Sub Status' && this.unitHistoryMaster[i].StatType == 'Sub-Status') {
          this.unitHistory.push(this.unitHistoryMaster[i]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';
#modal__departmentSearch {
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.perPageDropdown {
  margin-right: 100px;
  margin-bottom: 25px;
}
.red {
  color: rgb(180, 0, 0);
  font-weight: bold;
}

.export-excel__btn {
  cursor: pointer;
  padding: 0 10px;
  width: 168px;
  height: 30px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: white;
  height: 40px;
  display: flex;
  background-color: rgb(83, 172, 103);
  border: 3px solid rgb(120, 196, 138);
  border-radius: 40px;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.75);
  justify-content: space-between;
  align-items: center;
}

.search-link {
  font-size: 1.2rem;
  margin-right: 50px;
}

.department-search {
  position: relative;
  margin-top: 0px;
  top: -20px;
}

.unit-hub-div {
  margin-top: -20px;
}

.unit-hub-link {
  font-size: 1.2rem;
}

.router-link {
  height: 20px;
}

.status-filter {
  margin-top: -35px;
}
</style>
