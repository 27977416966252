// import Vue from 'vue';
import { createStore } from 'vuex';

// modules
import users from './modules/users';
import ui from './modules/ui';
import trucks from './modules/trucks';

// Vue.use(Vuex);
const store = createStore({
  modules: {
    users,
    ui,
    trucks,
  },
});

export default store;

// export default new Vuex.Store({
//   modules: {
//     users,
//     ui,
//     trucks,
//   },
// });
