export default class VueSocketIOListener {
  static staticEvents = [
    'connect',
    'error',
    'disconnect',
    'reconnect',
    'reconnect_attempt',
    'reconnecting',
    'reconnect_error',
    'reconnect_failed',
    'connect_error',
    'connect_timeout',
    'connecting',
    'ping',
    'pong',
  ];

  constructor(io, emitter) {
    this.io = io;
    this.register();
    this.emitter = emitter;
  }

  register() {
    this.io.onevent = packet => {
      let [event, ...args] = packet.data;

      if (args.length === 1) args = args[0];

      this.onEvent(event, args);
    };
    VueSocketIOListener.staticEvents.forEach(event =>
      this.io.on(event, args => this.onEvent(event, args))
    );
  }

  onEvent(event, args) {
    this.emitter.emit(event, args);
  }
}
