<template>
    <div id="modal__removePrepayOrder">
      <form v-on:submit.prevent="handleRemovePrepay" class="centered">
        <b>Confirm this order should no longer be billed as Prepay.</b>
        <BRow align-h="between">
          <BCol cols="auto">
            <div class="remove-prepay-div">
              <BButton 
              variant="danger" 
              class="remove-prepay-confirm"
              @click="handleRemovePrepay()">
                Yes
              </BButton>
              <BButton 
              variant="secondary" 
              @click="$emit('closeModal');" 
              class="remove-prepay-confirm">
                No
              </BButton>
            </div>
          </BCol>
        </BRow>
      </form>
      <p id="prepayOnboarding__error">{{ prepayError }}</p>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import axios from 'axios';
  import constants from '@/assets/constants';
  
  export default {
    name: 'removePrepayOrder',
    data() {
      return {
        prepayError: '',
      };
    },
    computed: mapGetters(['getCurrentNoteOrder']),
    methods: {
      ...mapActions(['closeModal', 'fetchAllOrders']),
      handleRemovePrepay() {
        axios.put(`${constants.BASE_URL}/api/order/remove-prepay`, {
          OrderFormID: this.getCurrentNoteOrder.OrderFormID,
          FormHistoryID: this.getCurrentNoteOrder.FormHistoryID,
          ChangedField: 'ConfirmedPrepay',
          OldValue: 1,
          NewValue: 0
        })
        .then(res => {
          this.$socket.emit('onBoardingCardsRefresh');
          this.$socket.emit('arCardsRefresh');
          this.closeModal();
        })
        .catch(error => {
          console.log(error);
          this.prepayError = error.response ? error.response.data.error : 'Unable to remove prepay.';
          setTimeout(() => (this.prepayError = ''), 9000);
        })
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/scss/index';
  
  #modal__removePrepayOrder {
    min-width: 100%;
    min-height: 100%;
    @include F($d: column);
  }
  
  form {
    width: 80%;
    div {
      width: 100%;
      position: relative;
      textarea {
        height: 100%;
        width: 100%;
        outline: none;
        padding: 10px;
        border-radius: 3px;
        resize: none;
      }
      p {
        position: absolute;
        left: 10px;
        bottom: 10px;
        color: $barcoLightBlueFont;
      }
    }
  }
  
  #prepayOnboarding__error {
    height: 20px;
    margin: 0 350px 5px 0;
    color: red;
    font-weight: 600;
  }
  
  h1 {
    font-weight: 600;
    margin: 0 0 15px 0;
  }

  .centered {
    margin-left: auto;
  }
  
  .submit-prepay-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 150px 10px 0;
  }
  .remove-prepay-btn {
    margin-top: 25%;
    margin-left: 35%;
  }
  .remove-prepay-confirm {
    margin: 12%;
  }
  </style>
  