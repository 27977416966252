<template>
	<div id="modal__splitOrder">
		<BRow align-h="between" class="card_split_container">
			<div v-for="(split, i) in Splits" :key="split.SplitNumber" class="split-card-width">
  			<div class="boarding-card-container">
					<div class="top-info-container">
						<div class="boarding-card width-100">
							<div class="split-remove-container">
								<font-awesome-icon
								class="split-remove"
								icon="minus-circle"
								v-if="i != 0"
								@click="removeSplit(i)"
								/>
							</div>
							<i class="boarding-card__ttl"
								>{{ order.CorporateName }}</i
							>
							<div class="width-50">
								<h6>Trucks Remaining: 
									<!-- <font-awesome-icon v-if="split.Reservations.length < split.Remaining && split.Remaining > 1" class="subtract-count" icon="minus-circle" /> -->
									<b>{{ split.Remaining }}</b>
									<font-awesome-icon v-if="!MaxReserves" class="add-count" icon="plus-circle" @click="addCount(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>Total on Order: <b>{{ split.Total }}</b></h6>
							</div>
							<div class="width-50">
								<h6>1/2 Tons: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Half >= 1" class="subtract-count" icon="minus-circle" @click="minusHalf(i)" />
									<b>{{ split.Half }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.HalfTonCount != 0 && split.Half < order.HalfTonCount" class="add-count" icon="plus-circle" @click="addHalf(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>3/4 Tons: 
									<font-awesome-icon v-if="!SingleTruckCount && split.ThreeQ >= 1" class="subtract-count" icon="minus-circle" @click="minusThreeQ(i)" />
									<b>{{ split.ThreeQ }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.ThreeQuarterTonCount != 0 && split.ThreeQ < order.ThreeQuarterTonCount" class="add-count" icon="plus-circle" @click="addThreeQ(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>Flatbeds: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Flat >= 1" class="subtract-count" icon="minus-circle" @click="minusFlat(i)" />
									<b>{{ split.Flat }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.OneTonFlatCount != 0 && split.Flat < order.OneTonFlatCount" class="add-count" icon="plus-circle" @click="addFlat(i)" />
								</h6>
							</div>
							<div class="width-50">
								<h6>Service Body: 
									<font-awesome-icon v-if="!SingleTruckCount && split.Service >= 1" class="subtract-count" icon="minus-circle" @click="minusService(i)" />
									<b>{{ split.Service }}</b>
									<font-awesome-icon v-if="!SingleTruckCount && order.OneTonServiceCount != 0 && split.Service < order.OneTonServiceCount" class="add-count" icon="plus-circle" @click="addService(i)" />
								</h6>
							</div>
							<div class="width-100"><h6>Reservations: {{ split.Reservations.length }}</h6></div>
							<VueDraggable 
								v-model="split.Reservations" 
								group="reserve"
								@start="drag=true" 
								@end="dropped"
								:move="checkMove"
								ghostClass='ghost'
								:id="i"
								class="drag-zone">
								<div v-for="unit in split.Reservations" :key="unit.ReservedUnit" class="width-100">
									<BButton variant="secondary" class="draggable-buttons">{{ unit.ReservedUnit }}</BButton>
								</div>
							</VueDraggable>
						</div>
					</div>
				</div>
			</div>
			<div v-if="OriginalRemaining > 0 && OriginalRequested > 1" class="split-card-width">
  			<div class="boarding-card-container">
					<div class="top-info-container">
						<div class="add-split-card width-100">
							<div class="load-more-btn" @click="addSplit()">
								<p class="plus-btn plus-btn-split">+</p>
								<p class="load-more-text add-split-text">Add Split</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BRow><br>
		<BRow align-h="between" class="width-100">
			<BCol cols="auto">
				<BButton variant="outline-secondary float-start" @click="$emit('closeModal')">Cancel</BButton>
			</BCol>
			<BCol cols="auto">
				<BSpinner v-if="Splitting">Splitting...</BSpinner>
				<BButton v-else variant="outline-primary float-end" @click="confirmSplit">Split</BButton>
			</BCol>
		</BRow>
		<BRow v-if="splitError">
			<h6 class="red">{{ splitError }}</h6>
		</BRow>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';
import { VueDraggable } from 'vue-draggable-plus';
// import EventBus from '../../utils/helpers/eventBus';

export default {
  props: ['order'],
	components: {
		VueDraggable,
	},
	name: 'splitOrderModal',
	data() {
		return {
			Splits: [{
				SplitNumber: 0,
				Remaining: this.order.TrucksRemaining,
				Total: this.order.TrucksRequested,
				Half: this.order.HalfTonCount,
				ThreeQ: this.order.ThreeQuarterTonCount,
				Flat: this.order.OneTonFlatCount,
				Service: this.order.OneTonServiceCount,
				Reservations: []
			}],
			OriginalRemaining: this.order.TrucksRemaining,
			OriginalRequested: this.order.TrucksRequested,
			MaxReserves: false,
			Splitting: false,
			splitError: '',
			SingleTruckCount: false,
			TruckType: '',
		};
	},
	computed: mapGetters(['getCurrentNoteOrder']),
	created() {
		axios
			.get(`${constants.BASE_URL}/api/getOrderReservations/` + this.order.FormHistoryID, {
				headers: { Authorization: localStorage.getItem('token') },
			})
			.then(result => {
				for (let i = 0; i < result.data.length; i++) {
					this.Splits[0].Reservations.push( 
					{
						ReservedUnit: result.data[i].UnitNumber,
						FormHistoryID: result.data[i].FormHistoryID,
						OrderFormID:	result.data[i].OrderFormID,
						SplitNumber: 0
					});
				}
				if (result.data.length >= this.OriginalRemaining) {
					this.MaxReserves = true;
				}
			}).catch(err => {
				console.log('error: ', err);
			});
		if (this.order.TrucksRequested == this.order.HalfTonCount ||
		this.order.TrucksRequested == this.order.ThreeQuarterTonCount ||
		this.order.TrucksRequested == this.order.OneTonFlatCount ||
		this.order.TrucksRequested == this.order.OneTonServiceCount) {
			this.SingleTruckCount = true;
			if (this.order.HalfTonCount != 0) {
				this.TruckType = 'Half';
			} else if (this.order.ThreeQuarterTonCount != 0) {
				this.TruckType = 'ThreeQ';
			} else if (this.order.OneTonFlatCount != 0) {
				this.TruckType = 'Flat';
			} else if (this.order.OneTonServiceCount != 0) {
				this.TruckType == 'Service';
			}
		}
	},
	methods: {
		...mapActions(['closeModal', 'fetchAllOrders']),
		addSplit() {
			let lastReserve = [];
			let half = 0;
			let threeQ = 0;
			let flat = 0;
			let service = 0;
			let remainSub = false;
			for (let i = 0; i < this.Splits.length; i++) {
				if (this.Splits[i].Remaining > 0) {
					this.Splits[i].Total = this.Splits[i].Total - 1;
					if (this.Splits[i].Reservations.length > 1 && 
					(this.Splits[i].Remaining < this.Splits[i].Reservations.length ||
					this.Splits[i].Reservations.length > this.Splits[i].Total)) {
						this.Splits[i].Remaining = this.Splits[i].Remaining - 1;
						remainSub = true;
						lastReserve.push(this.Splits[i].Reservations.pop());
						lastReserve[0].SplitNumber = this.Splits[i].SplitNumber;
					}
					if (this.Splits[i].Reservations.length == 0) {
						this.Splits[i].Remaining--;
						remainSub = true;
					}
					if (this.SingleTruckCount) {
						if (this.TruckType == 'Half') {
							this.Splits[i].Half--;
							half = 1;
						} else if (this.TruckType == 'ThreeQ') {
							this.Splits[i].ThreeQ--;
							threeQ = 1;
						} else if (this.TruckType == 'Flat') {
							this.Splits[i].Flat--;
							flat = 1;
						} else if (this.TruckType == 'Service') {
							this.Splits[i].Service--;
							service = 1;
						}
					}
					// Don't keep removing remaining after first one found available
					break;
				} 
				// else if (this.Splits[i].Remaining == 1 && this.Splits.Total > 1) {

				// }
			}
			this.Splits.push({
				SplitNumber: this.Splits[this.Splits.length - 1].SplitNumber + 1,
				Remaining: remainSub ? 1 : 0,
				Total: 1,
				Half: half,
				ThreeQ: threeQ,
				Flat: flat,
				Service: service,
				Reservations: lastReserve
			});
			this.OriginalRemaining--;
			this.OriginalRequested--;
		},
		removeSplit(i) {
			this.Splits[0].Remaining += this.Splits[i].Remaining;
			this.Splits[0].Total += this.Splits[i].Total;
			this.Splits[0].Reservations = this.Splits[0].Reservations.concat(this.Splits[i].Reservations);
			this.Splits[0].Half += this.Splits[i].Half;
			this.Splits[0].ThreeQ += this.Splits[i].ThreeQ;
			this.Splits[0].Flat += this.Splits[i].Flat;
			this.Splits[0].Service += this.Splits[i].Service;
			this.Splits.splice(i, 1);
			this.OriginalRemaining++;
			this.OriginalRequested++;
		},
		async confirmSplit() {
			this.Splitting = true;
			for (let i = 0; i < this.Splits.length; i++) {
				if ((this.Splits[i].Half + this.Splits[i].ThreeQ + this.Splits[i].Flat + this.Splits[i].Service) != this.Splits[i].Total) {
					this.Splitting = false;
					this.splitError = 'Please make sure "1/2 Tons + 3/4 Tons + Flatbeds + Service Body" count matches Total on Order for all split orders.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				} else if (this.Splits[i].Total == 0) {
					this.Splitting = false;
					this.splitError = 'Cannot split order with 0 Trucks on it.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				} else if (this.Splits[i].Reservations.length > this.Splits[i].Remaining) {
					this.Splitting = false;
					this.splitError = 'Cannot split order with more reservations than remaining trucks on the order.';
          setTimeout(() => (this.splitError = ''), 10000);
					return;
				}
			}
			if (this.Splits.length > 1) {
				await axios
        .put(`${constants.BASE_URL}/api/split-order`, {
					Splits: this.Splits,
					OgOrder: this.order,
				})
        .then(res => {
					this.Splitting = false;
					// EventBus.emit('splitComplete');
					this.$socket.emit('transportCardsRefresh');
					this.$socket.emit('onBoardingCardsRefresh');
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
					console.log(err);
					this.splitError = 'Unable to complete split.';
          setTimeout(() => (this.splitError = ''), 6000);
        });
			} else {
				this.splitError = 'Must have at least one split.';
				this.Splitting = false;
				setTimeout(() => (this.splitError = ''), 6000);
			}
		},
		dropped(evt) {
			this.drag = false;
			if (this.Splits[evt.to.id].Reservations.length > this.Splits[evt.to.id].Remaining) {
				if ((this.Splits[evt.from.id].Remaining > 1 || this.Splits[evt.from.id].Reservations.length == 0) && this.Splits[evt.to.id].Reservations.length > this.Splits[evt.to.id].Remaining) {
					this.Splits[evt.to.id].Remaining++;
					this.Splits[evt.from.id].Remaining--;
					if (this.Splits[evt.to.id].Remaining > this.Splits[evt.to.id].Total) {
						this.Splits[evt.to.id].Total++;
						this.Splits[evt.from.id].Total--;
					}
				}
				// if (this.Splits[evt.from.id].Remaining > 1 || this.order.TrucksRemaining != this.order.TrucksRequested) {
				// }
				if (this.SingleTruckCount && (this.Splits[evt.from.id].Half + this.Splits[evt.from.id].ThreeQ + this.Splits[evt.from.id].Flat + this.Splits[evt.from.id].Service) < this.Splits[evt.from.id].Total) {
					if (this.TruckType == 'Half') {
						this.Splits[evt.from.id].Half--;
						this.Splits[evt.to.id].Half++;
					} else if (this.TruckType == 'ThreeQ') {
						this.Splits[evt.from.id].ThreeQ--;
						this.Splits[evt.to.id].ThreeQ++;
					} else if (this.TruckType == 'Flat') {
						this.Splits[evt.from.id].Flat--;
						this.Splits[evt.to.id].Flat++;
					} else if (this.TruckType == 'Service') {
						this.Splits[evt.from.id].Service--;
						this.Splits[evt.to.id].Service++;
					}
				}
			}
		},
		addCount(i) {
			if (i == 0) {
				for (let x = 1; x < this.Splits.length; x++) {
					if (this.Splits[x].Remaining > this.Splits[x].Reservations.length && this.Splits[x].Remaining > 0) {
						this.Splits[x].Remaining--;
						this.Splits[i].Remaining++;
						if (this.Splits[i].Remaining > this.Splits[i].Total) {
							this.Splits[x].Total--;
							this.Splits[i].Total++;
						}
						if (this.SingleTruckCount) {
							if (this.TruckType == 'Half') {
								this.Splits[x].Half--;
								this.Splits[i].Half++;
							} else if (this.TruckType == 'ThreeQ') {
								this.Splits[x].ThreeQ--;
								this.Splits[i].ThreeQ++;
							} else if (this.TruckType == 'Flat') {
								this.Splits[x].Flat--;
								this.Splits[i].Flat++;
							} else if (this.TruckType == 'Service') {
								this.Splits[x].Service--;
								this.Splits[i].Service++;
							}
						}
						break;
					}
				}
			} else if (this.Splits[0].Remaining > this.Splits[0].Reservations.length) {
				this.Splits[0].Remaining--;
				this.Splits[i].Remaining++;
				if (this.Splits[i].Remaining > this.Splits[i].Total) {
					this.Splits[0].Total--;
					this.Splits[i].Total++;
				}
				if (this.SingleTruckCount) {
					if (this.TruckType == 'Half') {
						this.Splits[0].Half--;
						this.Splits[i].Half++;
					} else if (this.TruckType == 'ThreeQ') {
						this.Splits[0].ThreeQ--;
						this.Splits[i].ThreeQ++;
					} else if (this.TruckType == 'Flat') {
						this.Splits[0].Flat--;
						this.Splits[i].Flat++;
					} else if (this.TruckType == 'Service') {
						this.Splits[0].Service--;
						this.Splits[i].Service++;
					}
				}
			} else {
				for (let x = 1; x < this.Splits.length; x++) {
					if (x != i && this.Splits[x].Remaining > this.Splits[x].Reservations.length && this.Splits[x].Remaining > 0) {
						this.Splits[x].Remaining--;
						this.Splits[i].Remaining++;
						if (this.Splits[i].Remaining > this.Splits[i].Total) {
							this.Splits[x].Total--;
							this.Splits[i].Total++;
						}
						if (this.SingleTruckCount) {
							if (this.TruckType == 'Half') {
								this.Splits[x].Half--;
								this.Splits[i].Half++;
							} else if (this.TruckType == 'ThreeQ') {
								this.Splits[x].ThreeQ--;
								this.Splits[i].ThreeQ++;
							} else if (this.TruckType == 'Flat') {
								this.Splits[x].Flat--;
								this.Splits[i].Flat++;
							} else if (this.TruckType == 'Service') {
								this.Splits[x].Service--;
								this.Splits[i].Service++;
							}
						}
						break;
					}
				}
			}
		},
		checkMove: function(evt) {
			// console.log('starting: ', evt.draggedContext.element);
			// console.log('target: ', evt.relatedContext);
			// console.log('max reserves: ', this.MaxReserves);
			// console.log('reservations: ', this.Splits[evt.from.id].Reservations.length);
			if (this.MaxReserves == true && this.Splits[evt.from.id].Reservations.length == 1) {
				if (this.order.TrucksRemaining == this.order.TrucksRequested) {
					return false;
				}
			}
    },
		addHalf(i) {
			this.Splits[i].Half++;
		},
		addThreeQ(i) {
			this.Splits[i].ThreeQ++;
		},
		addFlat(i) {
			this.Splits[i].Flat++;
		},
		addService(i) {
			this.Splits[i].Service++;
		},
		minusHalf(i) {
			this.Splits[i].Half--;
		},
		minusThreeQ(i) {
			this.Splits[i].ThreeQ--;
		},
		minusFlat(i) {
			this.Splits[i].Flat--;
		},
		minusService(i) {
			this.Splits[i].Service--;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__splitOrder {
	min-width: 100%;
	min-height: 100%;
	@include F($d: column);
}
.card_split_container {
  width: 90%;
  min-height: 65vh;
  @include F($j: flex-start);
  flex-wrap: wrap;
  position: relative;
}
.split-card-width {
	width: 45%;
}
.boarding-card-container {
  position: relative;
}
.boarding-card {
	background-color: #e3e1e1bf;
}
.width-50 {
	width: 50%;
}
.drag-zone {
	min-width: 200px;
	min-height: 200px;
	max-height: 380px;
	border: 1px solid lightgray;
	overflow-y: scroll;
}
.red {
	color: red;
}
.split-remove {
	color: red;
	margin-right: 10px;
	cursor: pointer;
}
.split-remove-container {
	width: 100%;
	position: absolute;
	text-align: right;
}
.subtract-count {
	font-size: .9rem;
	margin-right: 4px;
	margin-left: 2px;
	cursor: pointer;
}
.add-count {
	font-size: .9rem;
	margin-right: 2px;
	margin-left: 4px;
	cursor: pointer;
}
.draggable-buttons {
	margin: 5px;
}
::-webkit-scrollbar{
	width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 20px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover{
	background-color: #909090;
}
</style>
  