<template>
  <div id="modal__manheimDetails">
    <h1 id="create-cm__title">
      {{ currentListing.vin }}
    </h1>
    <div id="create-cm-form-container">
      <BRow align-h="between">
        <BCol>
          Year: <b>{{ currentListing.year }}</b><br>
          Make: <b>{{ currentListing.make }}</b><br>
          Model: <b>{{ currentListing.models ? currentListing.models[0] : '' }}</b><br>
          Trim: <b>{{ currentListing.trims ? currentListing.trims[0] : '' }}</b><br>
          Odometer: <b>{{ currentListing.odometer }}</b><br>
          Ext. Color: <b>{{ currentListing.exteriorColor }}</b><br>
          Int. Color: <b>{{ currentListing.interiorColor }}</b><br>
          Buy Now Price: <b>{{ currentListing.buyNowPrice }}</b><br>
          MMR: <b>{{ currentListing.mmrPrice }}</b><br>
        </BCol>
        <BCol>
          Auction: <b>{{ currentListing.facilitatingAuction }}</b><br>
          City: <b>{{ currentListing.locationCity }}</b><br>
          Zip: <b>{{ currentListing.locationFullZipcode }}</b><br>
          State: <b>{{ currentListing.pickupLocationState }}</b><br>
          Seller: <b>{{ currentListing.sellerName }}</b><br>
          Source: <b>{{ currentListing.source }}</b><br>
          Comments: <b>{{ currentListing.comments }}</b><br>
        </BCol>
      </BRow>
      <div>
        <img class="image" v-for="(image, i) in currentListing.images" :src="image.smallUrl" :key="i" @click="index = i">
        <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
      </div>
      <div class="create-cm__footer">
        <BButton
          id="buy-now-btn"
          size="sm"
          variant="outline-primary"
          @click="openBuyNow()"
          >Buy Now</BButton
        >
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import constants from '@/assets/constants';
  import VueGallerySlideshow from 'vue-gallery-slideshow';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'manheimDetails',
    components: {
      VueGallerySlideshow
    },
    data() {
      return {
        currentListing: {},
        images: [],
        index: null
      };
    },
    computed: mapGetters(['getModalData', 'getCurrentNoteOrder']),
    mounted() {
      this.currentListing = this.getCurrentNoteOrder;
      for (let i = 0; i < this.currentListing.images.length; i++) {
        this.images.push(this.currentListing.images[i].largeUrl);
      }
      console.log('listing: ', this.currentListing);
    },
    methods: {
      openBuyNow() {
        window.open(this.currentListing.mComVdpUrl, "_blank");
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/scss/index';
  
  #modal__manheimDetails {
    min-height: 100%;
    min-width: 100%;
  }
  #buy-now-btn {
    margin-top: 20px;
  }
  </style>
  