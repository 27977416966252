import axios from 'axios';
import store from '../../store';

axios.interceptors.request.use(
  (config) => {
    axios.defaults.headers.common.Authorization = localStorage.getItem(
      'token',
    );
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // if (window.location.pathname != '/employee-login') {
      store.dispatch('openModal');
      // }
    } else {
      console.log('axios error: ', error);
    }
    return Promise.reject(error);
  },
);
