/*
HOW THIS WORKS:
    routes:
        loops through permission ids array for the user and if one of the ids has the path name assigned to it in 'routeIds' it returns true
    features:
        loops through permission ids array for the user and if one of the ids has the feature name assigned to it in 'routeIds' it returns true
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
WHAT EACH PROPERTY CONTAINS:
routeIds -
    contains the 'routes' which are the path names this id unlocks and the 'features' this id unlocks which are specified on the component
TODO add the new properties unprotected routes and how to use
permittedRoute -
    runs on the router beforeEach method and ensure the user has access to the route or a 404 page will be displayed
permittedFeature -
    will still render the page however if the component is not supposed to be seen by all users it will check the permission list
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
HOW TO ADD YOUR  NEW PERMISSION:
route -
    if route has no restrictions
        add to unrestricted array
    else
        1) add the route path to the permission id you want to have access to it
        2) do a dance you're done
permission -
    if feature has no restrictions
        you don't need to add anything at all just walk away slowly
    else
        1) add "v-if=permittedFeature('componentName')" to you feature container (pass in a descriptive name of the feature)
        2) add the feature name of your specified to the id in 'routeIds' before that you would like this to have access to
        3) all done but no dancing for you
*/

import jwtDecode from 'jwt-decode';

const auth = {
  // ========== PERMISSION ID KEY ==========
  routeIds: {
    // - 1 has full access
    1: {
      routes: ['restricted'],
      features: ['restricted'],
    },
    2: {
      routes: ['parts', 'recon'],
      features: [],
    },
    3: {
      routes: ['trucks'],
      features: ['on-off-rents'],
    },
    4: {
      routes: ['fleetcard'],
      features: [],
    },
    5: {
      routes: ['profit-loss'],
      features: [],
    },
    6: {
      routes: ['industry'],
      features: [],
    },
    7: {
      routes: ['monthsinfleet', 'statuscount', 'daily-counts'],
      features: [],
    },
    8: {
      routes: ['lot-auction-count'],
      features: ['lot-and-auction'],
    },
    9: {
      routes: ['fleetpie'],
      features: [],
    },
    10: {
      routes: ['onboarding'],
      features: [],
    },
    11: {
      routes: ['transport'],
      features: [],
    },
    12: {
      routes: ['client-relations'],
      features: [],
    },
    13: {
      routes: ['accounts-receivable', 'credit-memos'],
      features: [],
    },
    14: {
      routes: ['offboarding'],
      features: [],
    },
    15: {
      routes: ['sales'],
      features: [],
    },
    16: {
      routes: ['titles'],
      features: [],
    },
    17: {
      routes: [],
      features: ['transport-edit', 'alt-vins'],
    },
    18: {
      routes: ['truck-details'],
      features: ['on-rent-optional-services'],
    },
    19: {
      routes: [],
      features: ['notification-status'],
    },
    20: {
      routes: ['employee-management', 'register'],
      features: [],
    },
    21: {
      routes: ['company-report'],
      features: [],
    },
    22: {
      routes: ['new-company'],
      features: [],
    },
    23: {
      routes: [],
      features: ['sales-tax-upload'],
    },
    24: {
      routes: ['auction-report'],
      features: [],
    },
    25: {
      routes: ['mileage-report'],
      features: [],
    },
    26: {
      routes: [],
      features: ['credit-memo-approve'],
    },
    27: {
      routes: ['cm-open-detail', 'cm-all-detail'],
      features: [],
    },
    28: {
      routes: [],
      features: ['onboarding-approve'],
    },
    29: {
      routes: [],
      features: ['offboarding-approve'],
    },
    30: {
      routes: ['invoice-report'],
      features: [],
    },
    31: {
      routes: [],
      features: ['dnb-upload'],
    },
    32: {
      routes: ['custom-report'],
      features: [],
    },
    33: {
      routes: [],
      features: ['credit-memo-edit-void'],
    },
    34: {
      routes: [],
      features: ['edit-ar'],
    },
    35: {
      routes: ['bmr'],
      features: [],
    },
    36: {
      routes: [],
      features: ['custom-report-advanced'],
    },
    37: {
      routes: ['status-diff'],
      features: []
    },
    38: {
      routes: ['truck-count-by-state', 'truck-count-by-state-new'],
      features: ['truck-count-by-state']
    },
    39: {
      routes: ['on-rent-optional-services'],
      features: ['on-rent-optional-services']
    },
    40: {
      routes: ['billing'],
      features: ['billing']
    },
    41: {
      routes: [],
      features: ['bmr-calculations']
    },
    42: {
      routes: ['manheim-listings'],
      features: []
    },
    43: {
      routes: ['days-in-status-report'],
      features: []
    },
    44: {
      routes: [],
      features: ['edit-delivery-date']
    },
    45: {
      routes: [],
      features: ['recon-claims-report']
    },
    46: {
      routes: [],
      features: ['set-rental-use']
    },
    47: {
      routes: [],
      features: ['daily-claims-report']
    },
    48: {
      routes: ['days-on-rent-report'],
      features: []
    },
    49: {
      routes: ['activity-tracker'],
      features: []
    },
    50: {
      routes: ['page-analytics'],
      features: []
    }
  },

  // ========== UNPROTECTED / ALL ACCESS ROUTES ==========
  // - Allow no token required
  unprotectedRoutes: [
    // 'notFound',
    'login',
    'orderForm',
    'offRentForm',
    'tradeForm',
    'damageForm',
    'passwordResetExternal',
  ],
  unprotectedPathnames: [
    '/employee-login',
    '/order-form',
    '/trade-form',
    '/off-rent-form',
    '/damage-form',
    '/password-reset-external',
    '/not-found'
  ],

  // - Allow token required but no permissions
  allAccessRoutes: ['home', 'home-apps', 'profile', 'overview', 'client-info', 'documentation'],
  // ========== PAGE AUTH ==========
  async permittedRoute(pathName) {
    // - Allows unprotectedRoutes
    if (this.unprotectedRoutes.includes(pathName)) {
      return true;
    }

    // - Verifies token and allows devs or allAccessRoute
    const localToken = localStorage.getItem('token');
    if (!localToken) return false;
    const userPermissionArr = await jwtDecode(localToken.split(' ')[1]).permissions;
    if (userPermissionArr.includes(1) || this.allAccessRoutes.includes(pathName)) {
      return true;
    }

    // - Verifies if users have access
    let userHasAccess = false;
    userPermissionArr.forEach(permissionId => {
      if (this.routeIds[permissionId].routes.includes(pathName)) {
        userHasAccess = true;
      }
    });

    return userHasAccess;
  },
  // ========== NAV AUTH ==========
  permittedNavLink() {
    // - Allows unprotectedRoutes
    if (this.unprotectedPathnames.includes(window.location.pathname)) {
      return true;
    }
    const userPermissionArr = jwtDecode(localStorage.getItem('token')).permissions;
    const authorizedRoutes = [];

    if (userPermissionArr.includes(1)) {
      for (const authId in this.routeIds) {
        this.routeIds[authId].routes.forEach(route => authorizedRoutes.push(route));
      }
      return authorizedRoutes;
    }

    for (const authId in this.routeIds) {
      if (userPermissionArr.includes(+authId)) {
        this.routeIds[authId].routes.forEach(route => authorizedRoutes.push(route));
      }
    }
    
    return authorizedRoutes;
  },
  // ========== FEATURE AUTH ==========
  permittedFeatures() {
    // - Allows unprotectedRoutes
    if (this.unprotectedPathnames.includes(window.location.pathname)) {
      return true;
    }
    const userPermissionArr = jwtDecode(localStorage.getItem('token')).permissions;
    const authorizedFeatures = [];

    if (userPermissionArr.includes(1)) {
      for (const authId in this.routeIds) {
        this.routeIds[authId].features.forEach(route => authorizedFeatures.push(route));
      }
      return authorizedFeatures;
    }

    for (const authId in this.routeIds) {
      if (userPermissionArr.includes(+authId)) {
        this.routeIds[authId].features.forEach(route => authorizedFeatures.push(route));
      }
    }
    return authorizedFeatures;
  },
};

export default auth;

// Todo matt: test all user with permissions and finished filling in the restricted  features
