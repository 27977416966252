<template>
  <div id="modal__noteOnboarding">
    <p id="noteOnboarding__error">{{ noteError }}</p>
    <form v-on:submit.prevent="handleCancellation">
      <div>
        <textarea
          v-model="note"
          @input="handleCharacterCount"
          maxlength="500"
          placeholder="Enter Cancellation Note"
          @keydown.tab.prevent="tabFriendly($event)"
        ></textarea>
        <p>{{ noteCharCount }}/500</p>
        <button hidden />
      </div>
    </form>
    <div class="submit-note-btn">
      <BButton size="sm" class="mt-2" @click="handleCancellation" variant="outline-danger"
        >Add Note and Cancel</BButton
      >
      <BSpinner v-if="spinners.cancel" id="b-spinner--add-btn__spinner"></BSpinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'noteOnboarding',
  data() {
    return {
      spinners: {
        cancel: false,
      },
      cancellationReqSent: false,
      note: '',
      noteCharCount: 0,
      noteError: '',
      department: '',
    };
  },
  computed: mapGetters(['getCurrentNoteOrder']),
  methods: {
    ...mapActions(['closeModal', 'fetchAllOrders']),
    handleCancellation() {
      if (this.note.trim() === '') {
        setTimeout(() => (this.noteError = ''), 4000);
        return (this.noteError = '*You must include a note to cancel the order.');
      }
      if (this.getCurrentNoteOrder.OffRentFormID) {
        this.department = 'Offboarding';
      } else {
        this.department = 'Onboarding';
      }
      this.spinners.cancel = true;
      axios
        .put(`${constants.BASE_URL}/api/order/cancel`, {
          note: this.note,
          FormHistoryID: this.getCurrentNoteOrder.FormHistoryID,
          department: this.department,
          offboardingStatus: this.getCurrentNoteOrder.OffboardingStatus,
          onboardingStatus: this.getCurrentNoteOrder.OnboardingStatus,
          CompanyName: this.getCurrentNoteOrder.CorporateName || this.getCurrentNoteOrder.CorporateNameTrade,
          CompanyNumber: this.getCurrentNoteOrder.CompanyNumber || this.getCurrentNoteOrder.CompanyNumberTrade,
          Trucks: this.getCurrentNoteOrder.Trucks || this.getCurrentNoteOrder.TruckTrade
        })
        .then(res => {
          if (this.department === 'Onboarding') {
            this.$socket.emit('onBoardingCardsRefresh');
          } else {
            this.$socket.emit('offBoardingCardsRefresh');
          }
          this.spinners.cancel = false;
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(error => {
          console.log(error);
          this.noteError = error.response ? error.response.data.error : 'Unable to save your note';
          setTimeout(() => (this.noteError = ''), 4000);
        });
    },
    handleCharacterCount(e) {
      this.noteCharCount = this.note.length;
    },
    tabFriendly(event) {
      let text = this.note,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);

      text = `${textStart}\t${textEnd}`;
      event.target.value = text;
      event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__noteOnboarding {
  min-width: 100%;
  min-height: 100%;
  @include F($d: column);
}

form {
  height: 36vh;
  width: 80%;
  div {
    height: 100%;
    width: 100%;
    position: relative;
    textarea {
      height: 100%;
      width: 100%;
      outline: none;
      padding: 10px;
      border-radius: 3px;
      // box-shadow: .5px .5px .5px #c9c9c9;
      resize: none;
    }
    p {
      position: absolute;
      left: 10px;
      bottom: 10px;
      color: $barcoLightBlueFont;
    }
  }
}

#noteOnboarding__error {
  height: 20px;
  margin: 0 350px 5px 0;
  color: red;
  font-weight: 600;
}

h1 {
  font-weight: 600;
  margin: 0 0 15px 0;
}

.submit-note-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 150px 10px 0;
}
</style>
