// currencyFilter.js
export function currencyFilter(value, symbol, decimal) {
  let locale = 'en-US';
  let currency = 'USD';
  if (!symbol || symbol == '$' || symbol == '') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: decimal || decimal == 0 ? decimal : 2
    }).format(value);
  } else if (symbol && symbol == '%') {
    return new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: decimal
    }).format(value);
  } else if (symbol && symbol == '.') {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      maximumFractionDigits: decimal
    }).format(value);
  } else if (value) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 2
    }).format(value);
  } else {
    return '';
  }
}
