<template>
  <div id="modal__passwordReset">
    <!-- <h1 v-if="!submitted">
      Password Reset
    </h1> -->
    <div v-if="submitted" class="success-checkmark">
      <div class="check-icon">
        <span class="icon-line line-tip"></span>
        <span class="icon-line line-long"></span>
        <div class="icon-circle"></div>
        <div class="icon-fix"></div>
      </div>
    </div>
    <div v-if="submitted" class="center-div">
      <h4 class="success">Email Sent Successfully!</h4>
      <h6>Check your inbox for the email.</h6>
      <br />
    </div>
    <form v-if="!message.length">
    <div class="error-message__container">
    <p id="message">{{ message }}</p>
    <p id="error">{{ error }}</p>
    </div>
      <input v-model="userEmail" type="email" placeholder="Enter your email..." />
      <button type="submit" hidden />
      <BButton
        v-if="!message.length && !loading"
        variant="outline-primary"
        @click="handleSendReset"
        >Send Reset Link</BButton
      >
      <BSpinner v-else></BSpinner>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'requestReset',
  data() {
    return {
      message: '',
      error: '',
      loading: false,
      userEmail: '',
      submitted: false,
    };
  },
  methods: {
    handleSendReset() {
      this.loading = true;
      axios
        .post(`${constants.BASE_URL}/users/password/request`, {
          Email: this.userEmail,
        })
        .then(res => {
          this.loading = false;
          this.submitted = true;
          this.message = 'A reset link has been sent to your email...';
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.error = '*Account could not be found for this email...';
          setTimeout(() => (this.error = ''), 6000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#error {
  color: #DC3545;
}

#message {
  color: #40B883;
}

form {
  margin-bottom: 5px;
  @include F($d: column);
  width: 60%;
  input {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid #999;
    margin: 0 0 3rem;
    font-size: 1.3rem;
    padding: 5px 5px;
  }
}

#modal__passwordReset {
  min-height: 25vh;
  @include F($d: column);
}

.error-message__container {
  width: 100%;

}

h1 {
  margin: 0 0 3rem;
  font-weight: 600;
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;
    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }
    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 8s ease-in;
    }
    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      transform: rotate(-45deg);
    }
    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;
      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 1.5s;
      }
      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 1.5s;
      }
    }
    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }
    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
}

.center-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}
</style>
