<template>
  <div class="app__main-vue">
    <Navbar v-if="token != null" />
    <router-view />
    <FeedBack />
    <Modal />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Modal from '@/components/Modal.vue';
import FeedBack from '@/components/general/FeedBack.vue';

export default {
  name: 'app',
  components: { Modal, Navbar, FeedBack },
  data() {
    return {
      token: null
    }
  },
  mounted() {
    this.token = localStorage.getItem('token');
  }
};
</script>

<style lang="scss" scoped>
  .app__main-vue {
    width: 100%;
    height: 100%;
  }
</style>
