import axios from 'axios';
import constants from '../../assets/constants';

const userStore = {
  state: {
    activeSocketGroups: false,
    userInfo: {},
    errors: {
      loginModal: [],
    },
  },
  getters: {
    getUserInfo: state => state.userInfo,
  },
  mutations: {
    updateUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    async login(_, { email, password, accessToken, idToken }) {
      let loginError = false;
      await axios
        .post(`${constants.BASE_URL}/users/login`, {
          email,
          password,
          accessToken,
          idToken,
        })
        .then(res => {
          localStorage.setItem('token', `Bearer ${res.data.token}`);
          localStorage.setItem('impersonating', false);
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
        })
        .catch(error => {
          if (error.response) {
            loginError = Object.values(error.response.data);
          }
          // console.log(error);
        });
      return loginError;
    },
    async logout(_) {
      localStorage.clear();
      window.location.href = '/employee-login';
      // window.location.reload();
    },
    async fetchUserInfo({ commit }) {
      if (Object.values(userStore.state.userInfo).length) return;
      let currentPath = window.location.pathname;
      if (currentPath != '/employee-login' && currentPath != '/order-form' && 
        currentPath != '/trade-form' && currentPath != '/off-rent-form') {
        axios
          .get(`${constants.BASE_URL}/users/info`, {
            headers: { Authorization: localStorage.getItem('token') },
          })
          .then(res => {
            commit('updateUserInfo', res.data);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
};

export default userStore;
