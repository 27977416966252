<template>
  <div id="modal__passwordReset">
    <form v-on:submit.prevent>
      <div class="resetField-container">
        <input
          :type="oldPasswordIsVisible ? 'text' : 'password'"
          v-model="oldPassword"
          name="oldPassword"
          placeholder="Old Password"
        />
        <div @click="toggleOldPassword" class="resetField__visibility-container">
          <font-awesome-icon v-if="oldPasswordIsVisible" class="visibilty__icon" icon="eye" />
          <font-awesome-icon v-else class="visibilty__icon" icon="eye-slash" />
        </div>
      </div>

      <div class="resetField-container">
        <input
          :type="passwordIsVisible ? 'text' : 'password'"
          v-model="password"
          name="password"
          placeholder="Password"
        />
        <div @click="togglePassword" class="resetField__visibility-container">
          <font-awesome-icon v-if="passwordIsVisible" class="visibilty__icon" icon="eye" />
          <font-awesome-icon v-else class="visibilty__icon" icon="eye-slash" />
        </div>
      </div>

      <div class="resetField-container">
        <input
          :type="confirmPasswordIsVisible ? 'text' : 'password'"
          v-model="confirmPassword"
          name="confirmPassword"
          placeholder="Confirm Password"
        />
        <div @click="toggleConfirmPassword" class="resetField__visibility-container">
          <font-awesome-icon v-if="confirmPasswordIsVisible" class="visibilty__icon" icon="eye" />
          <font-awesome-icon v-else class="visibilty__icon" icon="eye-slash" />
        </div>
      </div>
      <button @click="handlePasswordChange">Change</button>
    </form>
    <p id="reset-error">{{ passwordChangeError }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import constants from '@/assets/constants';

export default {
  name: 'passwordReset',
  data() {
    return {
      oldPassword: '',
      oldPasswordIsVisible: false,

      password: '',
      passwordIsVisible: false,

      confirmPassword: '',
      confirmPasswordIsVisible: false,

      passwordChangeError: '',
    };
  },
  computed: mapGetters(['getUserInfo']),
  methods: {
    ...mapActions(['closeModal']),
    async handlePasswordChange() {
      // missing or spaces
      // ! this will not let users reset their password if it is currently just a space
      if (
        this.password.trim() === '' ||
        this.confirmPassword.trim() === '' ||
        this.oldPassword.trim() === ''
      ) {
        this.password = '';
        this.confirmPassword = '';
        this.passwordChangeError = 'All fields required';
        return setTimeout(() => (this.passwordChangeError = ''), 5000);
      }

      // not matching
      if (this.password !== this.confirmPassword) {
        this.password = '';
        this.confirmPassword = '';
        this.passwordChangeError = 'New passwords must match';
        return setTimeout(() => (this.passwordChangeError = ''), 5000);
      }

      // length requirement
      if (this.password.length < 6 || this.confirmPassword.length < 6) {
        this.password = '';
        this.confirmPassword = '';
        this.passwordChangeError = 'New password must be at least 6 characters';
        return setTimeout(() => (this.passwordChangeError = ''), 5000);
      }
      const passwordChanged = await axios
        .put(`${constants.BASE_URL}/users/changePassword/${this.getUserInfo.UserID}`, {
          oldPassword: this.oldPassword,
          newPassword: this.password,
          newPasswordConfirm: this.confirmPassword,
        })
        .then(res => {
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
          console.log(err);

          this.passwordChangeError = err.response
            ? err.response.data.error
            : 'Old password is incorrect';
          setTimeout(() => (this.passwordChangeError = ''), 5000);
        });
    },
    toggleOldPassword() {
      this.oldPasswordIsVisible = !this.oldPasswordIsVisible;
    },
    togglePassword() {
      this.passwordIsVisible = !this.passwordIsVisible;
      this.confirmPasswordIsVisible = this.passwordIsVisible;
    },
    toggleConfirmPassword() {
      this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
      this.passwordIsVisible = this.confirmPasswordIsVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#reset-error {
  color: red;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

#modal__passwordReset {
  height: 60vh;
  @include F($d: column);
}

h1 {
  font-size: 2rem;
  margin: 0 0 5%;
  font-weight: 500;
}

.visibilty__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover {
    cursor: pointer;
  }
}

.resetField-container {
  @include F();
  width: 100%;
  input {
    padding: 5px 10px;
    margin: 20px 0;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid #111;
    outline: none;
  }
}

.resetField__visibility-container {
  position: relative;
  height: 25px;
  width: 25px;
  &:hover {
    cursor: pointer;
  }
}

form {
  @include F($d: column);
  width: 60%;

  button {
    background: none;
    border: none;
    outline: none;
    border: 1px solid $barcoLightBlueFont;
    color: $barcoLightBlueFont;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 5px 10px;
    align-self: flex-start;
    transition: all 200ms ease-in-out;
    &:hover {
      cursor: pointer;
      background: $barcoLightBlueFont;
      color: #fff;
    }
  }
}
</style>
