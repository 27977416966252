<template>
  <div id="modal__setPriorityOrder">
    <p id="priorityOnboarding__error">{{ priorityError }}</p>
    <form v-on:submit.prevent="handleSetPriority">
      <BRow alignH="between">
        <BCol>
          <div>
            <b>Priority Reason</b>
            <BFormRadioGroup
              id="priority-radio"
              v-model="priorityReason"
              name="Priority Reason"
            >
              <BFormRadio value="New Customer">New Customer</BFormRadio>
              <BFormRadio value="In Service Trade">In Service Trade</BFormRadio>
              <BFormRadio value="Expiring Registration Trade">Expiring Registration Trade</BFormRadio>
              <BFormRadio value="Hard Deadline">Hard Deadline</BFormRadio>
              <BFormRadio value="Other">Other</BFormRadio>
            </BFormRadioGroup>
            <BFormInput v-if="priorityReason == 'Other'" placeholder="Priority Reason"
              id="otherReason"
              maxlength="64"
              class="other-priority-input"
              v-model="priorityOther"
              @input="handleCharacterCount"
            ></BFormInput>
            <span v-if="priorityReason == 'Other'" class="char-counter">{{ priorityCharCount }}/64</span>
            <br>
            <b>Priority Deadline</b>
            <datepicker
              :format="format"
              :bootstrap-styling="true"
              v-model="priorityDate"
              class="priority-datepicker datepicker"
              week-start="0"
              required
              :enable-time-picker="false"
              :timezone="{ timezone: 'UTC', exactMatch: true }"
              ignore-time-validation
              auto-apply
              vertical
              arrow-navigation
            ></datepicker>
          </div>
        </BCol>
        <BCol v-if="getCurrentNoteOrder.Priority == 'Yes' || !!getCurrentNoteOrder.PriorityReason">
          <BButton 
          v-if="!removePriority"
          variant="outline-danger" 
          class="remove-priority-btn" 
          @click="removePriority = true">
            Remove Priority
          </BButton>
          <div v-if="removePriority" class="remove-priority-div">
            <b>Are you sure you want to remove priority status from this order?</b>
            <BButton 
            variant="danger" 
            class="remove-priority-confirm"
            @click="handleRemovePriority()">
              Yes
            </BButton>
            <BButton 
            variant="secondary" 
            @click="removePriority = false" 
            class="remove-priority-confirm">
              No
            </BButton>
          </div>
        </BCol>
      </BRow>
    </form>
    <div class="submit-note-btn">
      <BButton class="mt-2" @click="handleSetPriority" variant="outline-primary"
        >Set Priority</BButton
      >
      <BSpinner v-if="spinners.cancel" id="b-spinner--add-btn__spinner"></BSpinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Datepicker from 'vuejs3-datepicker';
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  components: {},
  name: 'setPriorityOrder',
  data() {
    return {
      spinners: {
        cancel: false,
      },
      priorityCharCount: 0,
      priorityError: '',
      priorityReason: '',
      priorityOther: '',
      priorityDate: new Date(new Date().setHours(0,0,0,0)),
      format: 'MM/dd/yyyy',
      removePriority: false,
    };
  },
  computed: mapGetters(['getCurrentNoteOrder']),
  created() {
    if (this.getCurrentNoteOrder.PriorityReason) {
      this.priorityReason = this.getCurrentNoteOrder.PriorityReason;
      let timeOffset = new Date(this.getCurrentNoteOrder.PriorityDeadline);
      // get time offset to account for timezone change since db is saving as date and not datetime
      timeOffset = (timeOffset.getTime() - timeOffset.getTimezoneOffset() * 60 * 1000) % (3600 * 1000 * 24);
      this.priorityDate = Date.parse(this.getCurrentNoteOrder.PriorityDeadline) + timeOffset;
    }
  },
  methods: {
    ...mapActions(['closeModal', 'fetchAllOrders']),
    handleSetPriority() {
      if (this.priorityReason == '') {
        setTimeout(() => (this.priorityError = ''), 4000);
        return (this.priorityError = '*You must select a priority reason.');
      } else if (this.priorityReason == 'Other' && this.priorityOther.trim() === '') {
        setTimeout(() => (this.priorityError = ''), 4000);
        return (this.priorityError = '*You must enter a priority reason when Other is selected.');
      } else if (this.priorityReason == 'Other') {
        this.priorityReason = this.priorityOther;
      }
      this.spinners.cancel = true;
      axios
        .put(`${constants.BASE_URL}/api/order/set-priority`, {
          PriorityReason: this.priorityReason,
          PriorityDeadline: this.priorityDate,
          FormHistoryID: this.getCurrentNoteOrder.FormHistoryID,
          ChangedField: 'PriorityReason',
          OldValue: this.getCurrentNoteOrder.PriorityReason || '',
          NewValue: this.priorityReason
        })
        .then(res => {
          this.$socket.emit('onBoardingCardsRefresh');
          this.$socket.emit('transportCardsRefresh');
          this.spinners.cancel = false;
          this.$emit('closeModal');
        })
        .catch(error => {
          console.log(error);
          this.noteError = error.response ? error.response.data.error : 'Unable to set priority on Order.';
          setTimeout(() => (this.noteError = ''), 4000);
        });
    },
    handleRemovePriority() {
      axios.put(`${constants.BASE_URL}/api/order/remove-priority`, {
        PriorityReason: null,
        PriorityDeadline: null,
        FormHistoryID: this.getCurrentNoteOrder.FormHistoryID,
        ChangedField: 'PriorityReason',
        OldValue: this.getCurrentNoteOrder.PriorityReason,
        NewValue: ''
      })
      .then(res => {
        this.$socket.emit('onBoardingCardsRefresh');
        this.$socket.emit('transportCardsRefresh');
        this.spinners.cancel = false;
          this.$emit('closeModal');
      })
      .catch(error => {
        console.log(error);
        this.noteError = error.response ? error.response.data.error : 'Unable to remove priority.';
        setTimeout(() => (this.noteError = ''), 4000);
      })
    },
    handleCharacterCount(e) {
      this.priorityCharCount = this.priorityOther.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__setPriorityOrder {
  min-width: 100%;
  min-height: 100%;
  @include F($d: column);
}

form {
  width: 80%;
  div {
    width: 100%;
    position: relative;
    textarea {
      height: 100%;
      width: 100%;
      outline: none;
      padding: 10px;
      border-radius: 3px;
      resize: none;
    }
    p {
      position: absolute;
      left: 10px;
      bottom: 10px;
      color: $barcoLightBlueFont;
    }
  }
}

#priorityOnboarding__error {
  height: 20px;
  margin: 0 350px 5px 0;
  color: red;
  font-weight: 600;
}

h1 {
  font-weight: 600;
  margin: 0 0 15px 0;
}

.submit-priority-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 150px 10px 0;
}
.vdp-datepicker .form-control {
  margin-top: 5px;
  background: none;
}
.priority-datepicker {
  width: 175px;
  margin-top: 5px !important;
}
.priority-datepicker > .input-group > .form-control {
  padding-top: 25px;
  padding-bottom: 18px;
}
.char-counter {
  font-size: .7rem;
  margin-left: 95%;
}
.remove-priority-btn {
  margin-top: 25%;
  margin-left: 35%;
}
.remove-priority-div {
  margin-top: 15%;
  margin-left: 25%;
}
.remove-priority-confirm {
  margin: 12%;
}
</style>
