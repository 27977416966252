<template>
  <div id="modal__editOffBoarding">
    <h5>Order Information:</h5>
    <div :class="orderErrors.compNameEmpty" class="edit-container">
      <b>Company Name:</b>
      <input
        type="text"
        v-model="orderData.compName"
        name="Company Name"
        :data-originalData="originalData.compName"
        @input="handleChange"
      />
    </div>
    <div id="edit__compInfo-container">
      <div id="edit__compInfoOne-wrapper">
        <div :class="orderErrors.compCodeEmpty" class="edit-container">
          <b>Company Code:</b>
          <input
            type="text"
            v-model="orderData.compCode"
            name="Company Code"
            :data-originalData="originalData.compCode"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupDateEmpty" class="edit-container">
          <b>Submitted By:</b>
          <input
            type="text"
            v-model="orderData.submittedBy"
            name="Submitted By"
            :data-originalData="originalData.submittedBy"
            @input="handleChange"
          />
        </div>
      </div>
      <div id="edit__compInfoTwo-container">
        <div :class="orderErrors.submittedByEmpty" class="edit-container">
          <b>Pickup Date:</b>
          <datepicker
            size="sm"
            v-model="orderData.pickupDate"
            @date-update="dateClicked"
            :enable-time-picker="false"
            :timezone="{ timezone: 'UTC', exactMatch: true }"
            ignore-time-validation
            week-start="0"
            auto-apply
            vertical 
            arrow-navigation
            required
          ></datepicker>
        </div>
      </div>
    </div>
    <div class="edit-container__vins">
      <b>Returning Vins:</b>
      <div class="edit__truckInfo-container">
        <div
          v-for="(truck, index) in orderData.returningVins"
          :key="truck.id"
          class="edit__truckInfo"
          :class="{ 'edit__lock--styleOverride': truck.assignedStatus == 'assigned' }"
        >
          <font-awesome-icon
            @click="truck.assignedStatus != 'assigned' && handleRemoveVin(index)"
            :class="truck.assignedStatus != 'assigned' ? 'edit__minus' : 'edit__lock'"
            :icon="truck.assignedStatus != 'assigned' ? 'minus-circle' : 'lock'"
          />
          <b>Unit Number:</b>
          <input
            :disabled="truck.assignedStatus == 'assigned' || (truck.statusID != 5 && truck.statusID != 0 && offboardingStatus != 0)"
            required
            v-mask="'XXXXXXXX'"
            @input="e => ((vinsEdited = true), (e.target.value = e.target.value.toUpperCase()))"
            v-model="truck.vin"
          />
          <b>Odometer:</b>
          <input
            :disabled="truck.assignedStatus == 'assigned'"
            required
            v-mask="'#########'"
            v-model="truck.odometer"
          />
          <p v-if="truck.assignedStatus == 'assigned'" class="edit__lockText">Carrier Assigned</p>
          <p
            v-if="truck.assignedStatus != 'assigned' && validatedUnits[truck.vin]"
            class="edit__unitStats valid"
          >
            VALID UNIT NUMBER
          </p>
          <p
            v-if="truck.assignedStatus != 'assigned' && !validatedUnits[truck.vin] && !vinsEdited"
            class="edit__unitStats invalid"
          >
            INVALID UNIT NUMBER
          </p>
          <p
            v-if="truck.assignedStatus != 'assigned' && vinsEdited"
            class="edit__unitStats invalid"
          >
            Test Required
          </p>
        </div>
        <div class="edit__btns-container">
          <div @click="handleAddVin" id="edit__addVin">
            <font-awesome-icon class="edit__plus" icon="plus-circle" />
            <p>Add New Vin</p>
          </div>
          <div @click="handleVinTest" id="edit__addVin">
            <p id="edit__test">Validate Unit Numbers</p>
          </div>
        </div>
      </div>
    </div>
    <h5>Pickup Information:</h5>
    <div class="edit__pickInfo">
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.pickupNameEmpty" class="edit-container">
          <b>Name:</b>
          <input
            type="text"
            v-model="orderData.pickupName"
            name="Pickup Name"
            :data-originalData="originalData.pickupName"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupEmailEmpty" class="edit-container">
          <b>Email:</b>
          <input
            type="text"
            v-model="orderData.pickupEmail"
            name="Pickup Email"
            :data-originalData="originalData.pickupEmail"
            @input="handleChange"
          />
        </div>
        <div :class="orderErrors.pickupPhoneEmpty" class="edit-container">
          <b>Phone:</b>
          <input
            name="Pickup Name"
            :data-originalData="originalData.pickupPhone"
            @input="handleChange"
            v-mask="validatePickupPhone"
            type="text"
            v-model="orderData.pickupPhone"
          />
        </div>
        <div class="edit-container">
          <b>Alt. Phone:</b>
          <input
            name="Pickup Alt Phone"
            :data-originalData="originalData.pickupPhoneAlt"
            @input="handleChange"
            placeholder="*optional"
            v-mask="validatePickupAltPhone"
            type="text"
            v-model="orderDataUnrequired.pickupPhoneAlt"
          />
        </div>
      </div>
      <div id="edit__pickupInfoOne-container">
        <div :class="orderErrors.pickupStreetAddressEmpty" class="edit-container">
          <b>Street Address:</b>
          <div>
            <input
              name="Pickup Address"
              :data-originalData="originalData.pickupStreetAddress"
              @input="handleChange"
              type="text"
              v-model="orderData.pickupStreetAddress"
            />
          </div>
        </div>
        <div class="edit-container">
          <b>Street Address 2:</b>
          <div>
            <input
              name="Pickup Address 2"
              :data-originalData="originalData.pickupStreetAddress2"
              @input="handleChange"
              type="text"
              placeholder="*optional"
              v-model="orderDataUnrequired.pickupStreetAddress2"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupCityEmpty" class="edit-container">
          <b>City:</b>
          <div>
            <input
              name="Pickup City"
              :data-originalData="originalData.pickupCity"
              @input="handleChange"
              type="text"
              v-model="orderData.pickupCity"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupStateEmpty" class="edit-container">
          <b>State:</b>
          <div>
            <input
              name="Pickup State"
              :data-originalData="originalData.pickupState"
              @input="handleChange"
              type="text"
              v-model="orderData.pickupState"
            />
          </div>
        </div>
        <div :class="orderErrors.pickupZipEmpty" class="edit-container">
          <b>Zip Code:</b>
          <div>
            <input
              name="Pickup Zip"
              :data-originalData="originalData.pickupZip"
              @input="handleChange"
              type="text"
              v-model="orderData.pickupZip"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="submit-container">
      <BButton variant="success" @click="handleSaveChanges">Save Changes</BButton>
      <p id="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import constants from '@/assets/constants';

export default {
  name: 'editOffboarding',
  // computed: mapGetters(['getCurrentNoteOrder']),
  data() {
    return {
      error: '',
      vinsEdited: false,
      validatedUnits: {},
      originalData: {},
      orderData: {
        changedData: {},
        compName: '',
        compCode: '',
        pickupDate: null,
        submittedBy: '',
        returningVins: [],
        deletedVins: [],
        pickupName: '',
        pickupEmail: '',
        pickupPhone: '',
        pickupStreetAddress: '',
        pickupCity: '',
        pickupState: '',
        pickupZip: '',
      },
      orderDataUnrequired: {
        pickupPhoneAlt: '',
        pickupStreetAddress2: '',
      },
      orderErrors: {
        compNameEmpty: '',
        compCodeEmpty: '',
        pickupDateEmpty: '',
        submittedByEmpty: '',
        returningVinsEmpty: '',
        deletedVinsEmpty: '',
        pickupNameEmpty: '',
        pickupEmailEmpty: '',
        pickupPhoneEmpty: '',
        pickupStreetAddressEmpty: '',
        pickupStreetAddress2Empty: '',
        pickupCityEmpty: '',
        pickupStateEmpty: '',
        pickupZipEmpty: '',
      },
      statusList: [],
      offboardingStatus: '',
    };
  },
  computed: {
    ...mapGetters(['getCurrentNoteOrder']),
    validatePickupPhone() {
      if (this.orderData.pickupPhone == '') {
        return '';
      } else if (this.orderData.pickupPhone && (this.orderData.pickupPhone.substring(0, 1) == '+' || this.orderData.pickupPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    },
    validatePickupAltPhone() {
      if (this.orderData.pickupAltPhone == '') {
        return '';
      } else if (this.orderData.pickupAltPhone && (this.orderData.pickupAltPhone.substring(0, 1) == '+' || this.orderData.pickupAltPhone.substring(0, 1) == '1')) {
        return ['+# (###) ###-####', '+## (###) ###-####']
      } else {
        return '(###) ###-####';
      }
    }
  },
  mounted() {
    if (this.getCurrentNoteOrder.StatusList != null) {
      this.statusList = this.getCurrentNoteOrder.StatusList.split(',').map(unitStat => {
        const status = unitStat.split(' - ');
        return {
          unitNumber: status[0],
          statusID: status[1]
        }
      })
    }
    this.offboardingStatus = this.getCurrentNoteOrder.OffboardingStatus;
    this.orderData.TradeFormID = this.getCurrentNoteOrder.TradeFormID;
    this.orderData.FormHistoryID = this.getCurrentNoteOrder.FormHistoryID;
    this.orderData.OffRentFormID = this.getCurrentNoteOrder.OffRentFormID;
    this.orderData.compName = this.getCurrentNoteOrder.CorporateName;
    this.orderData.compCode =
      this.getCurrentNoteOrder.CompanyNumber || this.getCurrentNoteOrder.CompanyCode || '00000';
    this.orderData.compCode = this.orderData.compCode.toString().padStart(5, '0');
    this.orderData.pickupDate = new Date(this.getCurrentNoteOrder.ReturnDate || this.getCurrentNoteOrder.DateNeeded);
    this.orderData.pickupDate = this.orderData.pickupDate.toISOString().split('T')[0];
    this.orderData.submittedBy = this.getCurrentNoteOrder.SubmitteeName;
    this.orderData.returningVins = this.getCurrentNoteOrder.Trucks
      ? this.getCurrentNoteOrder.Trucks.split(',').map(vinInfo => {
          const response = vinInfo.split(' - ');
          let StatusID = 0;
          for (let i = 0; i < this.statusList.length; i++) {
            if (response[0] == this.statusList[i].unitNumber) {
              StatusID = this.statusList[i].statusID;
            }
          }
          return {
            originalInfo: {
              vin: response[0],
              odometer: response[1],
              assignedStatus: response[2],
            },
            vin: response[0],
            odometer: response[1],
            assignedStatus: response[2],
            statusID: StatusID
          };
        })
      : this.getCurrentNoteOrder.Truck.split(' # ,').map(vinInfo => {
          const response = vinInfo.split(' - ');
          let StatusID = 0;
          for (let i = 0; i < this.statusList.length; i++) {
            if (response[0] == this.statusList[i].unitNumber) {
              StatusID = this.statusList[i].statusID;
            }
          }
          return {
            originalInfo: {
              vin: response[0],
              odometer: response[5],
              assignedStatus: response[2],
            },
            vin: response[0],
            odometer: response[5],
            assignedStatus: response[2],
            statusID: StatusID
          };
        });

    this.orderData.pickupName =
      this.getCurrentNoteOrder.PickupName || this.getCurrentNoteOrder.Name;
    this.orderData.pickupEmail = this.getCurrentNoteOrder.PickupEmail || this.getCurrentNoteOrder.Email;
    this.orderData.pickupPhone =
      this.getCurrentNoteOrder.PickupPhone || this.getCurrentNoteOrder.Phone;
    this.orderDataUnrequired.pickupPhoneAlt = this.getCurrentNoteOrder.PickupAltPhone || this.getCurrentNoteOrder.AltPhone;
    this.orderData.pickupStreetAddress =
      this.getCurrentNoteOrder.PickupStreetAddress || this.getCurrentNoteOrder.StreetAddress;
    this.orderDataUnrequired.pickupStreetAddress2 =
      this.getCurrentNoteOrder.PickupStreetAddress2 || this.getCurrentNoteOrder.Street2;
    this.orderData.pickupCity =
      this.getCurrentNoteOrder.PickupCity || this.getCurrentNoteOrder.City;
    this.orderData.pickupState =
      this.getCurrentNoteOrder.PickupState || this.getCurrentNoteOrder.State;
    this.orderData.pickupZip = this.getCurrentNoteOrder.PickupZip || this.getCurrentNoteOrder.Zip;

    this.originalData = { ...this.orderData };
  },
  methods: {
    ...mapActions(['closeModal']),
    async handleSaveChanges() {
      // * Validates fields
      if (!(await this.handleValidate())) return;

      // * Validates Unit Numbers
      await this.handleVinTest();

      const testEnteredVins = this.orderData.returningVins.map(
        vinInfo => this.validatedUnits[vinInfo.vin]
      );

      if (testEnteredVins.includes(false)) {
        setTimeout(() => (this.error = ''), 5000);
        this.error = 'All edited vins must be tested and valid';
        return;
      }

      const vins = this.orderData.returningVins;
      // * Are there duplicate vins in the array?
      for (let i = 0; i < vins.length; i++) {
        for (let j = 0; j < vins.length; j++) {
          if (i !== j && vins[i].vin === vins[j].vin) {
            setTimeout(() => (this.error = ''), 5000);
            this.error = '*The entered vins contain duplicates';
            return;
          }
        }
      }

      if (this.orderData.compCode == '' || this.orderData.compCode == null) {
        this.error = 'Company Code is required to edit an order.'
      }

      if (this.error) return;


      for (let i = 0; i < this.orderData.returningVins.length; i++) {
        let ogInfo = this.orderData.returningVins[i].originalInfo;
        if (ogInfo && ogInfo.odometer.includes(' # ')) {
          ogInfo.odometer = ogInfo.odometer.replace(' # ', '');
        }
      }

      this.orderData = { ...this.orderData, ...this.orderDataUnrequired };
      await axios
        .put(`${constants.BASE_URL}/api/off-rent/edit`, this.orderData)
        .then(res => {
          if (this.$route.name === 'transport') {
            this.$socket.emit('transportCardsRefresh');
          } else {
            this.$socket.emit('offBoardingCardsRefresh');
          }
          // this.closeModal();
          this.$emit('closeModal');
        })
        .catch(err => {
          setTimeout(() => (this.error = ''), 5000);
          this.error = '*All fields must be filled...';
          console.log(err);
        });
    },
    handleAddVin() {
      this.orderData.returningVins.push({
        vin: 'UPDATE',
        odometer: '000000',
        assignedStatus: 'unassigned',
        statusID: 0
      });
    },
    handleRemoveVin(index) {
      this.orderData.deletedVins.push(this.orderData.returningVins[index]);
      this.orderData.returningVins.splice(index, 1);
    },
    async handleVinTest() {
      const duplicates = new Set();
      const containsDuplicates = this.orderData.returningVins.some(({ vin }) => {
        if (duplicates.has(vin)) return true;
        duplicates.add(vin);
      });
      if (containsDuplicates) {
        setTimeout(() => (this.error = ''), 6000);
        this.error = 'Cannot use duplicate vins';
        return;
      }

      await axios
        .post(`${constants.BASE_URL}/api/truck/vin-test`, {
          vins: this.orderData.returningVins,
          FormHistoryID: this.orderData.FormHistoryID,
        })
        .then(res => {
          this.validatedUnits = res.data;
          this.vinsEdited = false;
        })
        .catch(err => {
          console.log(error);
        });
    },
    handleValidate() {
      // - ============ SET THE TRUCKS REMAINING ============
      const validationData = { ...this.orderData };
      let validInformation = true;

      // * Nullable fields
      delete validationData.TradeFormID;
      delete validationData.deletedVins;
      delete validationData.pickupStreetAddress2;
      delete validationData.pickupZip;
      delete validationData.pickupPhoneAlt;
      this.getCurrentNoteOrder.NumTrucks !== this.getCurrentNoteOrder.TrucksRemaining &&
        delete validationData.returningVins;

      this.error = '';
      Object.values(validationData).forEach((field, index) => {
        try {
          if (
            (field !== false && !field && index !== 8) ||
            (field.toString().trim() === '' && index !== 8)
          ) {
            this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
            validInformation = false;
          } else {
            this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = '';
          }

          if (!validInformation) {
            setTimeout(() => (this.error = ''), 6000);
            this.error = '*All fields must be filled...';
          }
        } catch (err) {
          this.orderErrors[`${Object.keys(validationData)[index]}Empty`] = 'error-box';
          validInformation = false;
          this.error = '*All fields must be filled...';
        }
      });
      return validInformation;
    },
    handleChange(e) {
      let { name, value } = e.target;
      let oldValue = e.currentTarget
        ? e.currentTarget.getAttribute('data-originalData')
        : e.target.originalData;
      if (e.target.name == 'Company Code') {
        this.orderData.compCode = ('00000' + this.orderData.compCode).slice(-5);
        value = ('00000' + this.orderData.compCode).slice(-5);
      } else if (e.target.name == 'Pickup Date') {
        let pickup = new Date(e.target.originalData);
        oldValue = pickup.toISOString().split('T')[0];
      }
      if (oldValue === value) return delete this.orderData.changedData[name];
      name = name.replace(',', '').replace('!', '');
      value = value.replace(',', '').replace('!', '');
      if (oldValue !== null) {
        oldValue = oldValue.toString().replace(',', '').replace('!', '');
      }

      this.orderData.changedData[name] = `${name}: ${oldValue}!${name}: ${value}`;
    },
    dateClicked(date) {
      this.handleChange({
        target: {
          name: 'Pickup Date',
          value: date.toISOString().split('T')[0],
          originalData: this.originalData.pickupDate,
        },
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__editOffBoarding {
  width: 100%;
}

#error {
  margin-left: 15px;
  color: #df4b5b;
}

.error-box {
  border-radius: 3px;
  border: 1px solid #df4b5b;
  max-width: 300px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 5px;
}

.edit-container {
  @include F(flex-start, center);
  width: 100%;
  margin: 5px 0;
  b {
    margin: 0 15px 0 0;
    white-space: nowrap;
  }
  input {
    flex: 1 !important;
    width: 100%;
    border: none;
    outline: none;
    background: none;
  }
}

#edit__ttl {
  position: absolute;
  top: 0;
  font-weight: 600;
  transform: translateY(-130%);
}

.edit-container__vins {
  @include F(center, flex-start, column);
  width: 100%;
  margin: 10px 0;
  b {
    margin: 0 5px 0 0;
  }
  input {
    max-width: 90% !important;
    background: none;
    border: none;
    outline: none;
  }
}

.submit-container {
  display: flex;
  align-items: center;
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__lock {
  color: rgb(202, 19, 19);
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__lock--styleOverride {
  background: #db0f0f1c !important;
  border: 1px solid red;
  margin: 3px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
    width: 16%;
  }
}

.edit__lockText {
  font-weight: 700;
  text-transform: uppercase;
  color: #111;
}

.valid,
.invalid {
  font-weight: 600;
}

.valid {
  color: green;
}

.invalid {
  color: firebrick;
}

.edit__btns-container {
  margin: 10px 0 0;
  padding: 0 10px 0 0;
  @include F($j: space-between);
  width: 100%;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  p {
    font-weight: 600;
  }
}

#edit__test {
  color: orangered;
  &:hover {
    text-decoration: underline;
  }
}

.edit__truckInfo {
  background: #eee;
  margin: 3px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
    width: 40%;
  }
}

@media screen and (min-width: 850px) {
  .edit__truckInfo-container {
    @include F(center, flex-start, column);
    margin: 0 0 20px 30px;
    width: 70%;
  }

  #edit__compInfo-container {
    @include F(flex-start, flex-start);
  }

  .edit__truckInfo {
    @include F(flex-start, center);
    input {
      width: 16%;
    }
  }

  .edit__pickInfo {
    @include F(flex-start, flex-start);
    margin: 0 0 15px;
  }

  #edit__pickupInfoOne-container {
    width: 50%;
  }

  #edit__pickupInfoTwo-container {
    width: 50%;
  }
}

</style>
