// src/authMiddleware.js
export default function createAuthMiddleware(app) {
  return (to, from, next) => {
    const msalInstance = app.config.globalProperties.$msalInstance;
    if (!msalInstance) {
      console.error('MSAL instance is not available');
      next({ name: 'Login' });
      return;
    }

    const accounts = msalInstance.getAllAccounts();

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (accounts.length === 0) {
        next({ name: 'Login' });
      } else {
        next();
      }
    } else {
      next();
    }
  };
}
