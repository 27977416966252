<template>
  <div id="modal__passwordReset">
    <form v-on:submit.prevent>
      <!-- <div v-if="MSAL == false">
        <button class="btn btn-lg btn-darker btn-block" @click="microsoftLogin">Login with Microsoft</button>
        <div class="legacy-div">
          <p class="legacy-text" @click="MSAL = !MSAL">Use Legacy Sign In</p>
        </div>
        <h5 v-if="loginError" class="red">
          {{ errorPhrase }}
          <br />
        </h5>
      </div> -->
      <div>
        <div class="resetField-container">
          <input type="email" v-model="email" name="email" placeholder="Email" />
        </div>
        
        <div class="resetField-container">
          <input type="password" v-model="password" name="password" placeholder="Password" />
        </div>
        <button class="login-modal-btn" @click="handleLogin">Sign In</button>
        
        <div class="or-login-div">
          <hr class="or-login-hr">
          <p class="or-login-text">or</p>
        </div>
        <button class="btn btn-lg btn-darker btn-block" @click="microsoftLogin">
          <font-awesome-icon id="microsoft-icon" class="fa-microsoft" :icon="['fab', 'microsoft']" />
          Sign in with Microsoft
        </button>
        <!-- <div class="legacy-div">
          <p class="legacy-text" @click="MSAL = !MSAL">Sign in with Microsoft SSO instead.</p>
        </div> -->
      </div>

      <b v-for="(err, i) in error" :key="i">
        {{ err }}
      </b>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueSocketIO from '../../../utils/sockets/index';
import store from '../../../store';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      error: [],
      // MSAL: true,
    };
  },
  // mounted() {
  //   this.microsoftLogin();
  // },
  methods: {
    ...mapActions(['login', 'closeModal']),

    async handleLogin() {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.error = [];

      if (!emailRegex.test(this.email) || !this.password.trim() === '') {
        return this.error.push('Must include valid credentials');
      }

      const loginError = await this.login({
        email: this.email,
        password: this.password,
      });

      if (!loginError) {
        // this.closeModal();
        this.$emit('closeModal');
        // ? need to reload the whole window for the socket handshake to refresh
        return window.location.reload();
      }
      console.log(loginError);
      return this.error.push(...loginError);
    },
    async microsoftLogin() {
      const msalInstance = this.$msalInstance;
      if (!msalInstance) {
        console.log('MSAL instance not available');
        return;
      }
      await msalInstance.initialize();
      const loginRequest = {
        scopes: ['user.read']
      };
      try {
        const loginResponse = await this.$msalInstance.loginPopup(loginRequest);
        const loginError = await this.login({
          email: loginResponse.account.username,
          password: '',
          accessToken: loginResponse.accessToken,
          idToken: loginResponse.idToken
        });

        if (loginError) {
          this.loadingLogin = false;
          this.loginError = true;
          return this.error.push('Microsoft account not found.');
        } else {
          this.loadingLogin = false;
          this.$emit('closeModal');
          window.location.reload();
        }
        // Store token or send to your backend
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

b {
  padding: 20px 0;
  color: firebrick;
  text-transform: capitalize;
}

#modal__passwordReset {
  min-height: 100%;
  @include F($d: column, $j: flex-start);
}

h1 {
  font-size: 2rem;
  margin: 3rem 0 5%;
  font-weight: 500;
}

.resetField-container {
  @include F();
  width: 100%;
  input {
    padding: 5px 10px;
    margin: 10px 0;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid #111;
    outline: none;
  }
}
.legacy-text {
  cursor: pointer;
}

form {
  @include F($d: column);
  width: 60%;

  button {
    background: none;
    outline: none;
    border: 1px solid $barcoLightBlueFont;
    border-radius: 4px;
    width: 100%;
    color: $barcoLightBlueFont;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 5px 10px;
    align-self: flex-start;
    transition: all 200ms ease-in-out;
    margin: 0 0 25%;
    &:hover {
      cursor: pointer;
      background: $barcoLightBlueFont;
      color: #fff;
    }
  }
}
.login-modal-btn {
  margin-bottom: 0px;
  margin-top: 20px;
}
.or-login-div {
  text-align: center;
}
.or-login-text {
  margin-top: 22px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 25px;
  position: relative;
  z-index: 3;
}
.or-login-hr {
  margin-top: 35px;
  margin-bottom: -35px;
}

button.btn-darker {
  width: 100%;
  color: #fff;
  background-color: #292727;
  border-color: #1b1a1a;
  border-radius: 4px;
  margin-top: 25px;
}
.btn.btn-lg.btn-darker.btn-block:hover {
  color: $barcoLightBlueFont;
  background-color: #1b1b1b;
}
.fa-microsoft {
  margin-right: 10px;
  text-align: left !important;
}
</style>
