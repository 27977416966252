// import Mixin from './mixin';
// import Logger from './logger';
// import Listener from './listener';
// import Emitter from './emitter';
// import jwtDecode from 'jwt-decode';
// // import SocketIO from 'socket.io-client';

// // export default class VueSocketIO {
// //   constructor({ connection, vuex, debug, options }) {
// //     Logger.debug = debug;
// //     this.io = this.connect(connection, options);
// //     this.useConnectionNamespace = options && options.useConnectionNamespace;
// //     this.namespaceName = options && options.namespaceName;
// //     this.emitter = new Emitter(vuex);
// //     this.listener = new Listener(this.io, this.emitter);
// //   }

// //   install(Vue) {
// //     const namespace = this.namespaceName || this.io.nsp.replace('/', '');

// //     if (this.useConnectionNamespace) {
// //       if (typeof app.config.globalProperties.$socket === 'object') {
// //         app.config.globalProperties.$socket = {
// //           ...app.config.globalProperties.$socket,
// //           [namespace]: this.io,
// //         };

// //         app.config.globalProperties.$vueSocketIo = { ...app.config.globalProperties.$vueSocketIo, [namespace]: this };
// //       } else {
// //         app.config.globalProperties.$socket = {
// //           [namespace]: this.io,
// //         };
// //         app.config.globalProperties.$vueSocketIo = { [namespace]: this };
// //       }
// //     } else {
// //       // Vue.prototype.$socket = this.io;
// //       // Vue.prototype.$vueSocketIo = this;
// //       if (app && app.config) {
// //         app.config.globalProperties.$socket = this.io;
// //         app.config.globalProperties.$vueSocketIo = this;
// //       }
// //       // app.config.globalProperties.$socket = (value) => {
// //       //   return this.io;
// //       // };
// //       // app.config.globalProperties.$vueSocketIo = (value) => {
// //       //   return this;
// //       // }
// //     }

// //     Vue.mixin(Mixin);

// //     Logger.info('Vue-Socket.io plugin enabled');
// //   }

// //   connect(connection, options) {
// //     if (connection && typeof connection === 'object') {
// //       Logger.info(`Received socket.io-client instance`);
// //       return connection;
// //     } else if (typeof connection === 'string') {
// //       // - Auth
// //       let unwrappedToken;
// //       if (localStorage.getItem('token')) {
// //         unwrappedToken = jwtDecode(localStorage.getItem('token').split(' ')[1]);
// //       }
// //       let io = SocketIO(connection, {
// //         ...options,
// //         query: {
// //           authorized: unwrappedToken ? unwrappedToken.exp * 1000 > Date.now() : false,
// //         },
// //       });

// //       Logger.info(`Received connection string`);
// //       return (this.io = io);
// //     } else {
// //       throw new Error('Unsupported connection type');
// //     }
// //   }
// // }
// import { io } from 'socket.io-client';

// export default {
//   constructor({ connection, vuex, debug, options }) {
//     Logger.debug = debug;
//     this.io = this.connect(connection, options);
//     this.useConnectionNamespace = options && options.useConnectionNamespace;
//     this.namespaceName = options && options.namespaceName;
//     this.emitter = new Emitter(vuex);
//     this.listener = new Listener(this.io, this.emitter);
//   },
//   install: (app, { connection, options }) => {
//     console.log('options: ', options);
//     const socket = io(connection, options);
//     console.log('socket nsp: ', socket);
//     const namespace = options.namespaceName || socket.nsp.replace('/', '');
//     console.log('namespace: ', namespace);
//     if (options && options.useConnectionNamespace) {
//       if (typeof app.config.globalProperties.$socket === 'object') {
//         app.config.globalProperties.$socket = {
//           ...app.config.globalProperties.$socket,
//           [namespace]: socket,
//         };

//         app.config.globalProperties.$vueSocketIo = { ...app.config.globalProperties.$vueSocketIo, [namespace]: this };
//       } else {
//         app.config.globalProperties.$socket = {
//           [namespace]: socket,
//         };
//         app.config.globalProperties.$vueSocketIo = { [namespace]: this };
//       }
//     } else {
//       // Vue.prototype.$socket = this.io;
//       // Vue.prototype.$vueSocketIo = this;
//       if (app && app.config) {
//         app.config.globalProperties.$socket = socket;
//         app.config.globalProperties.$vueSocketIo = this;
//       }
//       // app.config.globalProperties.$socket = (value) => {
//       //   return this.io;
//       // };
//       // app.config.globalProperties.$vueSocketIo = (value) => {
//       //   return this;
//       // }
//     }





//     console.log('socket: ', socket);
//     // Add a global property
//     app.config.globalProperties.$socket = socket;

//     // // Initialize the $vueSocketIo property if it doesn't exist
//     // app.config.globalProperties.$vueSocketIo = app.config.globalProperties.$vueSocketIo || {};

//     // // Assign the namespace to $vueSocketIo
//     // app.config.globalProperties.$vueSocketIo[options.namespace] = options.socketInstance;
  
//     // Optionally, provide a global mixin to handle socket events
//     app.mixin(Mixin);

//     // Provide the socket instance to be injected in components if needed
//     app.provide('socket', socket);
//   },
//   connect(connection, options) {
//     if (connection && typeof connection === 'object') {
//       Logger.info(`Received socket.io-client instance`);
//       return connection;
//     } else if (typeof connection === 'string') {
//       // - Auth
//       let unwrappedToken;
//       if (localStorage.getItem('token')) {
//         unwrappedToken = jwtDecode(localStorage.getItem('token').split(' ')[1]);
//       }
//       let io = SocketIO(connection, {
//         ...options,
//         query: {
//           authorized: unwrappedToken ? unwrappedToken.exp * 1000 > Date.now() : false,
//         },
//       });

//       Logger.info(`Received connection string`);
//       return (this.io = io);
//     } else {
//       throw new Error('Unsupported connection type');
//     }
//   }
// };


import Mixin from './mixin';
import Logger from './logger';
import Listener from './listener';
import Emitter from './emitter';
import { io } from 'socket.io-client';
import jwtDecode from 'jwt-decode';

const SocketPlugin = {
  install(app, { connection, vuex, debug, options }) {
    Logger.debug = debug;

    const socket = this.connect(connection, options);
    const namespace = options.namespaceName || socket.nsp.replace('/', '');
    
    this.useConnectionNamespace = options && options.useConnectionNamespace;
    this.namespaceName = options && options.namespaceName;
    this.emitter = new Emitter(vuex);
    this.listener = new Listener(socket, this.emitter);

    if (options && options.useConnectionNamespace) {
      if (typeof app.config.globalProperties.$socket === 'object') {
        app.config.globalProperties.$socket = {
          ...app.config.globalProperties.$socket,
          [namespace]: socket,
        };

        app.config.globalProperties.$vueSocketIo = { ...app.config.globalProperties.$vueSocketIo, [namespace]: this };
      } else {
        app.config.globalProperties.$socket = {
          [namespace]: socket,
        };
        app.config.globalProperties.$vueSocketIo = { [namespace]: this };
      }
    } else {
      app.config.globalProperties.$socket = socket;
      app.config.globalProperties.$vueSocketIo = this;
    }

    // Optionally, provide a global mixin to handle socket events
    app.mixin(Mixin);

    // Provide the socket instance to be injected in components if needed
    app.provide('socket', socket);
  },
  connect(connection, options) {
    if (typeof connection === 'object') {
      Logger.info(`Received socket.io-client instance`);
      return connection;
    } else if (typeof connection === 'string') {
      let unwrappedToken;
      if (localStorage.getItem('token')) {
        unwrappedToken = jwtDecode(localStorage.getItem('token').split(' ')[1]);
      }
      const socket = io(connection, {
        ...options,
        query: {
          authorized: unwrappedToken ? unwrappedToken.exp * 1000 > Date.now() : false,
        },
      });

      Logger.info(`Received connection string`);
      return socket;
    } else {
      throw new Error('Unsupported connection type');
    }
  }
};

export default SocketPlugin;
